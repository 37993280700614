import ColorHash from "color-hash";
import _ from "lodash";
import { postData, baseAxiosUrl } from "../services/customApis";
import APIEndPoints from "./apiendpoints";
import { scrollIntoViewWithOffset } from "./menuscroll";
import moment from "moment";

export const ImageUtil = (name) => {
  const canvas = document.createElement("canvas");
  canvas.style.display = "none";
  canvas.width = 32;
  canvas.height = 32;
  document.body.appendChild(canvas);
  const context = canvas.getContext("2d");
  if (context) {
    let colorHash = new ColorHash();
    const colorNum = colorHash?.hex(name);
    context.fillStyle = colorNum;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "14px Arial";
    context.fillStyle = "#fff";
    const split = name.split(" ");
    const len = split?.length;
    const first = split[0][0];
    if (len > 1) {
      const last = split[len - 1][0];
      const initials = first + last;
      context.fillText(initials?.toUpperCase() ?? "U", 6, 21);
    } else {
      const last = split[0][1];
      const initials = first + last;
      context.fillText(initials?.toUpperCase() ?? "U", 6, 21);
    }
    const data = canvas.toDataURL();
    document.body.removeChild(canvas);
    return data;
  } else {
    return "";
  }
};

export function getBaseUrl(): string {
  return window.location.origin.indexOf("https://localhost") > -1
    ? "https://catalyst-dev.ey.com"
    : window.location.origin;
}

export const getPrivilegeValue = (
  privilagesForInternalApp,
  privilegeFor,
  privilegeType
) => {
  if (!privilagesForInternalApp) {
    return false;
  }
  const lowerCasePrivilegeFor = privilegeFor.toLowerCase();
  const lowerCasePrivilegeType = privilegeType.toLowerCase();

  const matchingKey = Object.keys(privilagesForInternalApp).find((key) =>
    key.toLowerCase().includes(lowerCasePrivilegeFor)
  );

  if (!matchingKey) {
    return false;
  }
  const privileges = privilagesForInternalApp[matchingKey];
  const matchingPrivilegeKey = Object.keys(privileges).find((privilegeKey) =>
    privilegeKey.toLowerCase().includes(lowerCasePrivilegeType)
  );

  return matchingPrivilegeKey ? privileges[matchingPrivilegeKey] : false;
};

export function downloadExcelFile(resultData, inputFileName) {
  const fileDetail = resultData?.fileDetail;
  if (
    typeof fileDetail !== "object" ||
    !fileDetail.hasOwnProperty("fileStream") ||
    fileDetail.fileStream == null
  ) {
    return false;
  }
  let base64Url = fileDetail?.fileStream;
  const fileType = fileDetail?.fileType;
  const filename = generateFileName(fileType, inputFileName);
  //convert base64 to array buffer
  const binaryString = window.atob(base64Url);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  //create blob obj and download file
  const blob = new Blob([bytes]);
  const fileName = `${filename}`;
  if ((navigator as any).msSaveBlob) {
    (navigator as any).msSaveBlob(blob, fileName);
    return true;
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
  }
  return false;
}

export function generateFileName(fileType, inputFileName) {
  const date = new Date();
  const timestamp = date.toISOString().replace(/:/g, "_");
  const fileName = inputFileName;
  return `${fileName}_${timestamp}.${fileType}`;
}

//Below code can be replaced as per company config api response implementation
export function getInstrumetationKey(): string {
  // console.log("window location origin: ", window.location.origin);
  if (window.location.origin.indexOf("https://localhost") > -1) {
    //DEV Instrumentation Key
    return "b41d63cb-0b2c-4a33-863b-77990a751def";
  } else if (
    window.location.origin.indexOf("https://catalyst-dev.ey.com") > -1
  ) {
    //DEV Instrumentation Key
    return "b41d63cb-0b2c-4a33-863b-77990a751def";
  } else if (
    window.location.origin.indexOf("https://catalyst-qa.ey.com") > -1
  ) {
    //QA Instrumentation Key
    return "0cc575fd-2963-40b2-bb2e-545c291d7380";
  } else if (
    window.location.origin.indexOf("https://catalyst-uat.ey.com") > -1
  ) {
    //UAT Instrumentation Key
    return "b0104b39-c62e-4dae-9114-188451e89426";
  } else if (window.location.origin.indexOf("https://catalyst.ey.com") > -1) {
    //PROD Instrumentation Key
    return "cffb1c8c-2617-44cf-a62a-d3b0e8599589";
  } else {
    //DEV Instrumentation Key
    return "b41d63cb-0b2c-4a33-863b-77990a751def";
  }
}

// Build query string from map of query parameter
export const toQueryString = (queryParams) => {
  let encodedQueryParams: string[] = [];
  for (let key in queryParams) {
    encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
  }
  return encodedQueryParams.join("&");
};

//Converts decimal to hex equivalent
const _decimalToHex = (number) => {
  var hex = number.toString(16);
  while (hex.length < 2) {
    hex = "0" + hex;
  }
  return hex;
};

// Parse hash parameters into key-value pairs
export const getHashParameters = () => {
  const hashParams: any = {};
  window.location.hash
    .substr(1)
    .split("&")
    .forEach(function (item) {
      let s = item.split("="),
        k = s[0],
        v = s[1] && decodeURIComponent(s[1]);
      hashParams[k] = v;
    });
  return hashParams;
};

// Generates RFC4122 version 4 guid (128 bits)
export const _guid = () => {
  // RFC4122: The version 4 UUID is meant for generating UUIDs from truly-random or
  // pseudo-random numbers.
  // The algorithm is as follows:
  //     Set the two most significant bits (bits 6 and 7) of the
  //        clock_seq_hi_and_reserved to zero and one, respectively.
  //     Set the four most significant bits (bits 12 through 15) of the
  //        time_hi_and_version field to the 4-bit version number from
  //        Section 4.1.3. Version4
  //     Set all the other bits to randomly (or pseudo-randomly) chosen
  //     values.
  // UUID                   = time-low "-" time-mid "-"time-high-and-version "-"clock-seq-reserved and low(2hexOctet)"-" node
  // time-low               = 4hexOctet
  // time-mid               = 2hexOctet
  // time-high-and-version  = 2hexOctet
  // clock-seq-and-reserved = hexOctet:
  // clock-seq-low          = hexOctet
  // node                   = 6hexOctet
  // Format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
  // y could be 1000, 1001, 1010, 1011 since most significant two bits needs to be 10
  // y values are 8, 9, A, B
  var cryptoObj = window.crypto; // for IE 11
  if (cryptoObj && cryptoObj.getRandomValues) {
    var buffer = new Uint8Array(16);
    cryptoObj.getRandomValues(buffer);
    //buffer[6] and buffer[7] represents the time_hi_and_version field. We will set the four most significant bits (4 through 7) of buffer[6] to represent decimal number 4 (UUID version number).
    buffer[6] |= 0x40; //buffer[6] | 01000000 will set the 6 bit to 1.
    buffer[6] &= 0x4f; //buffer[6] & 01001111 will set the 4, 5, and 7 bit to 0 such that bits 4-7 === 0100 = "4".
    //buffer[8] represents the clock_seq_hi_and_reserved field. We will set the two most significant bits (6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively.
    buffer[8] |= 0x80; //buffer[8] | 10000000 will set the 7 bit to 1.
    buffer[8] &= 0xbf; //buffer[8] & 10111111 will set the 6 bit to 0.
    return (
      _decimalToHex(buffer[0]) +
      _decimalToHex(buffer[1]) +
      _decimalToHex(buffer[2]) +
      _decimalToHex(buffer[3]) +
      "-" +
      _decimalToHex(buffer[4]) +
      _decimalToHex(buffer[5]) +
      "-" +
      _decimalToHex(buffer[6]) +
      _decimalToHex(buffer[7]) +
      "-" +
      _decimalToHex(buffer[8]) +
      _decimalToHex(buffer[9]) +
      "-" +
      _decimalToHex(buffer[10]) +
      _decimalToHex(buffer[11]) +
      _decimalToHex(buffer[12]) +
      _decimalToHex(buffer[13]) +
      _decimalToHex(buffer[14]) +
      _decimalToHex(buffer[15])
    );
  } else {
    var guidHolder = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    var hex = "0123456789abcdef";
    var r = 0;
    var guidResponse = "";
    for (var i = 0; i < 36; i++) {
      if (guidHolder[i] !== "-" && guidHolder[i] !== "4") {
        // each x and y needs to be random
        r = (Math.random() * 16) | 0;
      }
      if (guidHolder[i] === "x") {
        guidResponse += hex[r];
      } else if (guidHolder[i] === "y") {
        // clock-seq-and-reserved first hex is filtered and remaining hex values are random
        r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
        r |= 0x8; // set pos 3 to 1 as 1???
        guidResponse += hex[r];
      } else {
        guidResponse += guidHolder[i];
      }
    }
    return guidResponse;
  }
};

export const formatDate = (dateString) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    year: "numeric",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

export const formatDate2 = (dateString) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
export const formatDate3 = (dateString) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    year: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
export const formatDate4 = (dateString) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const formatDate5 = (dateString) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };
  return new Date(dateString)
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "-");
};

export const formatCurrentDate = () => {
  let date = new Date();
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
};

export const decodeString = (str) => {
  // Going backward: from byte-stream to percent-encoding, to original string.
  let decodedString = decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return decodedString;
};

export const ValidateTime = (val): boolean => {
  let regX = /^\d{1,2}:\d{2}?$/;
  if (val !== "" && val !== null) {
    return val.match(regX);
  }
  return false;
};

export const removeHTMLTags = (stringWithHTML): string => {
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = stringWithHTML;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
};

export const getDaysCount = (date) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let timeDifference = date.getTime() - currentDate.getTime();
  let daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

export const formatTextForADCard = (text) => {
  let msg = "";
  let newString = text.replaceAll("\n\n", " \n ");
  let replacedAgain = newString.replaceAll("\n", " \n\n");
  if (replacedAgain.indexOf("https://") > -1) {
    let spiltItem = replacedAgain.split(" ");
    spiltItem.map((eachItem) => {
      if (eachItem.indexOf("https://") > -1) {
        msg =
          msg + " " + "[" + "{Deeplink_URL}" + "](" + "{Deeplink_URL}" + ")";
      } else {
        msg = msg + " " + eachItem;
      }
      return msg;
    });
  }
  return msg;
};

export const formatBytes = (bytes, decimals = 2) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (!+bytes) return "0 Bytes";
  const commonNum = 1024;
  const decimalValue = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(commonNum));

  return `${parseFloat(
    (bytes / Math.pow(commonNum, i)).toFixed(decimalValue)
  )} ${sizes[i]}`;
};

export const buildDeepLink = (
  deeplinkUrl: string,
  contentType: number,
  companyId: number,
  metadataId: number,
  articleUrl: string,
  type: string,
  uniqueId: string,
  templateId: string,
  pinMetaDataId?: number,
  isBulletinBoard?: any,
  isGlobal?: any
) => {
  let encodedContext = "";
  let textToBeAppended = "";

  let BB = "false";
  if (
    window.location.pathname == "/" ||
    isBulletinBoard == true ||
    isBulletinBoard == "true"
  ) {
    BB = "true";
  }

  if (contentType === 4) {
    if (deeplinkUrl.includes("teams")) {
      encodedContext = encodeURI(
        `{"subEntityId": "${
          isGlobal != null || isGlobal != undefined ? isGlobal : false
        };${companyId};${contentType};${metadataId};${BB};${pinMetaDataId};${uniqueId};${templateId}"}`
      );
      deeplinkUrl = deeplinkUrl.replace("SUBENTITY_ID", encodedContext);
    } else {
      textToBeAppended = `${
        isGlobal != null || isGlobal !== undefined ? isGlobal : false
      };${companyId};${contentType};${metadataId};${BB};${pinMetaDataId};${uniqueId};${templateId}`;
      deeplinkUrl = deeplinkUrl + textToBeAppended;
    }
  } else {
    if (deeplinkUrl.includes("teams")) {
      encodedContext = encodeURI(
        `{"subEntityId": "${isGlobal};${companyId};${contentType};${metadataId};${BB};${pinMetaDataId}"}`
      );
      deeplinkUrl = deeplinkUrl.replace("SUBENTITY_ID", encodedContext);
    } else {
      textToBeAppended = `${isGlobal};${companyId};${contentType};${metadataId};${BB};${pinMetaDataId}`;
      deeplinkUrl = deeplinkUrl + textToBeAppended;
    }
  }
  // else if (contentType == 7) {
  //   encodedContext = encodeURI(
  //     `{"subEntityId": "${companyId};polls;${metadataId};${BB}"}`
  //   );
  //   deeplinkUrl = deeplinkUrl.replace("SUBENTITY_ID", encodedContext);
  // } else {
  //   if (articleUrl == "") {
  //     encodedContext = encodeURI(
  //       `{"subEntityId": "${companyId};${contentType};${metadataId};${BB}"}`
  //     );
  //     deeplinkUrl = deeplinkUrl.replace("SUBENTITY_ID", encodedContext);
  //   } else {
  //     encodedContext = encodeURI(
  //       `{"subEntityId": "${companyId};${contentType};${metadataId};${BB}"}`
  //     );
  //     deeplinkUrl = deeplinkUrl.replace("SUBENTITY_ID", encodedContext);
  //     //deeplinkUrl = articleUrl;
  //   }
  // }
  return deeplinkUrl;
};

export const getUrlParams = (param) => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const parameter = searchParams.get(param);
  return parameter;
};
export const showLayover = () => {
  document.body.style.backgroundColor = "white";
  document.body.style.position = "fixed";
  document.body.style.opacity = "1";
  document.body.style.pointerEvents = "none";
  document.body.style.width = "100%";
  document.body.style.height = "100%";
  document.body.style.top = "0";
};
export const hideLayover = () => {
  document.body.style.backgroundColor = "";
  document.body.style.position = "";
  document.body.style.opacity = "";
  document.body.style.pointerEvents = "";
  document.body.style.width = "";
  document.body.style.height = "";
  document.body.style.top = "";
};

export const formatEncodedURLs = (name) => {
  return btoa(unescape(encodeURIComponent(name)));
};

export const formatDecodedURLs = (name) => {
  return decodeURIComponent(escape(window.atob(name)));
};

export const customFormatter = (date) => {
  if (date) {
    return moment(date).format("MM-DD-YYYY");
  } else {
    return "";
  }
};

export const openPdfInNewTab = async (
  metaDataId: string,
  contentTypeId: string,
  isGlobal: boolean,
  companyId: string | null
): Promise<{ pdfUrl: string; statusCode: string }> => {
  let response = {
    pdfUrl: "",
    statusCode: "",
  };
  const param = {
    metaDataId: parseInt(metaDataId),
  };
  if (companyId === null) {
    companyId = getSelectedCompId();
  }

  try {
    const dataDetails = await postData(
      param,
      APIEndPoints.CONTENT_DETAILS_BY_ID(
        parseInt(contentTypeId),
        isGlobal,
        companyId
      )
    );

    if (dataDetails.data.statusCode === "STCS001") {
      if (dataDetails.data.resultData.contentInfo[0].contentFileInfo) {
        let item = dataDetails.data.resultData.contentInfo[0].contentFileInfo;
        let fileDetails = {
          repositoryType: item?.fileDetail?.repositoryType,
          containerId: item?.fileDetail?.containerId,
          directoryIdentifier: item?.fileDetail?.directoryIdentifier,
          fileIdentifier: item?.fileDetail?.fileIdentifier,
          actualFileName: item?.fileDetail?.actualFileName,
          fileSize: item?.fileDetail?.fileSize,
          fileType: item?.fileDetail?.fileType,
          fileDownloadUrl: item?.fileDetail?.fileType,
        };

        const res = await postData(fileDetails, APIEndPoints.GET_FILE_TOKEN);
        response.pdfUrl =
          baseAxiosUrl + APIEndPoints.DOWNLOAD_PDF_WITH_TOKEN + res.data;
        response.statusCode = "STCS001";
      }
    } else if (dataDetails.data.statusCode === "STCS002") {
      response.pdfUrl = "";
      response.statusCode = "STCS002";
    }
  } catch (error) {
    console.error("Error fetching PDF details:", error);
  }

  return response;
};

export const downloadPdf = async (
  metaDataId,
  contentType,
  pdfObj,
  companyId,
  isGlobal
) => {
  try {
    const response = await postData(
      {
        metaDataId: metaDataId,
        attachments: [pdfObj],
      },
      APIEndPoints.DOWNLOAD_IMAGES(contentType, isGlobal, companyId)
    );

    //console.log(response);
    if (!response.status) {
      console.log("Download attachment failure!");
      return;
    }

    const attachments = response.data;

    if (!attachments || attachments.length === 0) {
      console.log("No attachments found in the response");
      return;
    }

    // for (let i = 0; i < attachments.length; i++) {
    const { actualFileName, fileType } = pdfObj.fileDetail;

    // convert base64 to array buffer
    const binaryString = window.atob(
      attachments.replace("data:application/octet-stream;base64, ", "")
    );
    const bytes = new Uint8Array(binaryString.length);
    for (let j = 0; j < binaryString.length; j++) {
      const ascii = binaryString.charCodeAt(j);
      bytes[j] = ascii;
    }

    // create blob obj and download file
    const blob = new Blob([bytes]);
    const fileNameWithExtension = `${actualFileName}.${fileType}`;

    if (
      "msSaveBlob" in navigator &&
      typeof navigator.msSaveBlob === "function"
    ) {
      navigator.msSaveBlob(blob, fileNameWithExtension);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileNameWithExtension);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // console.log("Download attachment successful");
      }
    }
    // }
  } catch (error) {
    console.log("Error downloading attachment", error);
  }
};

export const base64SizeCal = (base64String) => {
  return Math.round(new Blob([base64String]).size / 1000);
};

export const resizeImage = (
  imgContent,
  isDefaultImg,
  performCompression,
  callbackFunc
) => {
  if (performCompression) {
    var image = new Image();
    image.addEventListener(
      "load",
      () => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        const originalWidth = image.width;
        const originalHeight = image.height;

        // get the data from canvas as 70% JPG (can be also PNG, etc.)
        if (!isDefaultImg) {
          if (image.src.includes("octet")) {
            const canvasWidth =
              originalWidth > 1000 ? 1000 * 0.1 : originalWidth * 0.1;
            const canvasHeight =
              originalHeight > 1000 ? 720 * 0.1 : originalHeight * 0.1;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            ctx?.drawImage(image, 0, 0, canvasWidth, canvasHeight);
            let imgUrl = canvas.toDataURL("image/png", 1);
            let size = base64SizeCal(imgUrl);
            if (size > 20) {
              resizeImageFurther(imgUrl).then((newImgUrl) => {
                callbackFunc(newImgUrl);
              });
            } else {
              callbackFunc(imgUrl);
            }
          } else {
            const canvasWidth =
              originalWidth > 1000 ? 1000 * 0.1 : originalWidth * 0.3;
            const canvasHeight =
              originalHeight > 1000 ? 720 * 0.1 : originalHeight * 0.3;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            ctx?.drawImage(image, 0, 0, canvasWidth, canvasHeight);
            let imgUrl = canvas.toDataURL("image/jpeg", 0.7);
            let size = base64SizeCal(imgUrl);
            if (size > 21) {
              resizeImageFurther(imgUrl).then((newImgUrl) => {
                callbackFunc(newImgUrl);
              });
            } else {
              callbackFunc(imgUrl);
            }
          }
        } else {
          callbackFunc(imgContent);
        }
      },
      false
    );
    image.src = imgContent;
  } else {
    callbackFunc(imgContent);
  }
  //return compressImageContent
};

export async function resizeImageFurther(dataUrl, targetFileSizeKb = 20) {
  //let originalFile = await urltoFile(dataUrl, "test.png", "image/png")!;
  let originalFileSize = base64SizeCal(dataUrl);
  //console.log("Original file size", originalFileSize);
  if (originalFileSize < targetFileSizeKb) return dataUrl; // File is already smaller

  let middle = 0.9;

  let result = "";
  let imgUrl = dataUrl;

  while (originalFileSize > targetFileSizeKb) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = document.createElement("img");

    const promise = new Promise<void>((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = reject;
    });

    img.src = imgUrl;

    await promise;

    canvas.width = Math.round(img.width * middle);
    canvas.height = Math.round(img.height * middle);
    context?.scale(canvas.width / img.width, canvas.height / img.height);
    context?.drawImage(img, 0, 0, canvas.width, canvas.height);
    result = canvas.toDataURL("image/png", 1);
    imgUrl = result;
    originalFileSize = base64SizeCal(result);
  }
  return result;
}

export const findMenuItem = (data, search) => {
  try {
    for (let i = 0; i < data?.length; i++) {
      const section = data[i];
      for (let j = 0; j < section?.menu?.length; j++) {
        const menu = section?.menu[j];
        for (let k = 0; k < menu?.submenu?.length; k++) {
          const submenu = menu?.submenu[k];
          if (submenu?.to === search) {
            return {
              section: { id: section?.id, name: section?.title },
              menu: { id: menu?.id, name: menu?.title },
              submenu: { id: submenu?.id, name: submenu?.title },
              to: submenu?.to,
            };
          }
        }
      }
    }
    return {
      section: {
        id: data[0].id,
        name: data[0].title,
      },
      menu: {
        id: data[0].menu[0].id,
        name: data[0].menu[0].title,
      },
      submenu: {
        id: "",
        name: "",
      },
    };
  } catch {
    return {
      section: {
        id: data[0].id,
        name: data[0].title,
      },
      menu: {
        id: data[0].menu[0].id,
        name: data[0].menu[0].title,
      },
      submenu: {
        id: "",
        name: "",
      },
    };
  }
};

export const updateLocalStorageData = (
  isBookmark,
  resetStorage,
  contentTypeName,
  metaDataId
) => {
  let dataFromLS = JSON.parse(localStorage.getItem(contentTypeName) || "{}");

  if (Object.keys(dataFromLS).length != 0) {
    if (dataFromLS?.allCardsData?.sections?.new == undefined) {
      const index = dataFromLS.allCardsData?.sections?.all?.findIndex((obj) => {
        return obj.metaDataId === parseInt(metaDataId);
      });
      if (index !== -1) {
        dataFromLS.allCardsData.sections.all[
          index
        ].customData.userLevel.bookmarked = isBookmark;
      }
    } else {
      const new_index = dataFromLS.allCardsData.sections.new.findIndex(
        (obj) => {
          return obj.metaDataId === parseInt(metaDataId);
        }
      );
      if (new_index !== -1) {
        dataFromLS.allCardsData.sections.new[
          new_index
        ].customData.userLevel.bookmarked = isBookmark;
      }
      const tp_index = dataFromLS.allCardsData.sections.topprevious.findIndex(
        (obj) => {
          return obj.metaDataId === parseInt(metaDataId);
        }
      );
      if (tp_index !== -1) {
        dataFromLS.allCardsData.sections.topprevious[
          tp_index
        ].customData.userLevel.bookmarked = isBookmark;
      }
    }

    localStorage.setItem(contentTypeName, JSON.stringify(dataFromLS));
  }
};

export const resetLocalStorage = (resultData, contentTypeName, metadataId) => {
  try {
    let dataItem = JSON.parse(localStorage.getItem(contentTypeName) || "{}");
    if (Object.keys(dataItem).length > 0) {
      if (dataItem?.allCardsData?.sections?.new === undefined) {
        const index = dataItem.allCardsData?.sections?.all?.findIndex((obj) => {
          return obj.metaDataId === metadataId;
        });
        if (index !== -1) {
          dataItem.allCardsData.sections.all[
            index
          ].customData.userLevel.bookmarked =
            resultData?.customData.userLevel.bookmarked;
          dataItem.allCardsData.sections.all[
            index
          ].customData.applicationLevel.likeCount =
            resultData?.customData.applicationLevel.likeCount;
          dataItem.allCardsData.sections.all[index].customData.userLevel.liked =
            resultData.customData.userLevel.liked;
          dataItem.allCardsData.sections.all[
            index
          ].customData.applicationLevel.viewCount =
            resultData?.customData.applicationLevel.viewCount;
        }
      } else {
        const new_index = dataItem.allCardsData.sections.new.findIndex(
          (obj) => {
            return obj.metaDataId === metadataId;
          }
        );
        if (new_index !== -1) {
          dataItem.allCardsData.sections.new[
            new_index
          ].customData.userLevel.bookmarked =
            resultData?.customData.userLevel.bookmarked;
          dataItem.allCardsData.sections.new[
            new_index
          ].customData.applicationLevel.likeCount =
            resultData?.customData.applicationLevel.likeCount;
          dataItem.allCardsData.sections.new[
            new_index
          ].customData.userLevel.liked = resultData.customData.userLevel.liked;
          dataItem.allCardsData.sections.new[
            new_index
          ].customData.applicationLevel.viewCount =
            resultData?.customData.applicationLevel.viewCount;
        }
        const tp_index = dataItem.allCardsData.sections.topprevious.findIndex(
          (obj) => {
            return obj.metaDataId === metadataId;
          }
        );
        if (tp_index !== -1) {
          dataItem.allCardsData.sections.topprevious[
            tp_index
          ].customData.userLevel.bookmarked =
            resultData?.customData.userLevel.bookmarked;
          dataItem.allCardsData.sections.topprevious[
            tp_index
          ].customData.applicationLevel.likeCount =
            resultData.customData.applicationLevel.likeCount;
          dataItem.allCardsData.sections.topprevious[
            tp_index
          ].customData.userLevel.liked = resultData.customData.userLevel.liked;
          dataItem.allCardsData.sections.topprevious[
            tp_index
          ].customData.applicationLevel.viewCount =
            resultData.customData.applicationLevel.viewCount;
        }
      }
      localStorage.setItem(contentTypeName, JSON.stringify(dataItem));
    }
  } catch (err) {
    console.log("Error in resetLocalStorage", err);
  }
};

export const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// export const getContentTypeNamesById = (id) => {
//   switch (id) {
//     case 1:
//       return "Tax Alerts";
//     case 2:
//       return "Tax Agenda";
//     case 3:
//       return "Others";
//     case 4:
//       return "Visualization Report";
//     case 5:
//       return "Conferences";
//     case 6:
//       return "Webcast";
//     case 7:
//       return "Polls";
//     case 8:
//       return "Guides";
//     case 9:
//       return "Perspective";
//     case 10:
//       return "Podcast";
//     case 11:
//       return "Executives";
//     case 12:
//       return "EY App Catalog";
//     case 13:
//       return "Apps";
//     case 50:
//       return "AppsDetails";
//     case 16:
//       return "Accounting Link";
//     default:
//       return "";
//   }
// };

export const getContentTypePathsById = (id) => {
  switch (id) {
    case 1:
      return `/taxalerts`;
    case 2:
      return `/taxagenda`;
    case 3:
      return `/`;
    case 4:
      return `/visualizationlibrary`;
    case 5:
      return `/conferences`;
    case 6:
      return `/webcasts`;
    case 7:
      return `/polls`;
    case 8:
      return `/guides`;
    case 9:
      return `/perspectives`;
    case 10:
      return `/podcasts`;
    case 11:
      return `/executives`;
    case 12:
      return `/eyappcatalog`;
    case 13:
      return `/apps`;
    case 50:
      return "/appsDetails";
    case 16:
      return `/accountinglink`;
    case 17:
      return `/apps/footnotesLink`;
    default:
      return ``;
  }
};

export const getContentTypeNamesById2 = (id) => {
  switch (id) {
    case 1:
      return "Tax Alerts";
    case 2:
      return "Tax Agenda";
    case 3:
      return "Others";
    case 4:
      return "Visualization Report";
    case 5:
      return "Conferences";
    case 6:
      return "Webcasts";
    case 7:
      return "Polls";
    case 8:
      return "Guides";
    case 9:
      return "Perspectives";
    case 10:
      return "Podcasts";
    case 11:
      return "Executives";
    case 12:
      return "EY App Catalog";
    case 13:
      return "Apps";
    case 50:
      return "AppsDetails";
    case 16:
      return "Accounting Link";
    case 17:
      return "FootNotes Link";
    default:
      return "";
  }
};

export const getMenuNamesBySubmenuId = (id) => {
  switch (id) {
    case 5:
    case 6:
    case 7:
      return "Events";
    case 8:
    case 11:
    case 12:
    case 13:
    case 14:
    case 16:
    case 46:
      return "Resources";
    case 15:
      return "EY App Catalog";
    case 26:
      return "Apps";
    case 23:
      return "Visualizations";
    default:
      return "";
  }
};

export const getRepliers = (commentData) => {
  let comments = commentData?.filter((item) => item?.isDeleted !== true);
  const replySummary = comments.reduce(
    (summary, comment) => {
      if (!summary.repliers.includes(comment.createdByName)) {
        summary.repliers.push(comment.createdByName);
      }
      summary.numReplies++;
      return summary;
    },
    { numReplies: 0, repliers: [] }
  );

  const { numReplies, repliers } = replySummary;

  let replySummaryText;
  if (repliers.length === 1) {
    replySummaryText = `${numReplies} ${
      numReplies > 1 ? "replies" : "reply"
    } from ${repliers[0]}`;
  } else if (repliers.length === 2) {
    replySummaryText = `${numReplies} replies from ${repliers.join(" and ")}`;
  } else if (repliers.length > 2) {
    const otherRepliersCount = repliers.length - 2;
    replySummaryText = `${numReplies} replies from ${repliers[0]}, ${
      repliers[1]
    }, and ${
      repliers.length === 3 ? repliers[2] : otherRepliersCount + " others"
    }`;
  } else {
    replySummaryText = `${numReplies} replies`;
  }
  return replySummaryText;
};

export const utf8ToBase64 = (str) => {
  try {
    return btoa(encodeURIComponent(str));
  } catch (error) {
    return btoa(encodeURIComponent("Something went wrong."));
  }
};

export const base64ToUtf8 = (base64String, doUnEscpae = false) => {
  try {
    return decodeURIComponent(atob(base64String));
  } catch (error) {
    return decodeURIComponent("Something went wrong.");
  }
};

export const sanitizeHTML = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  try {
    const scripts = div.getElementsByTagName("script");
    let i = scripts.length;
    while (i--) {
      scripts[i]?.parentNode?.removeChild(scripts[i]);
    }

    // Remove inline event handlers and javascript: URLs
    const all = div.getElementsByTagName("*");
    for (let i = 0, len = all.length; i < len; i++) {
      for (let j = 0, len2 = all[i].attributes.length; j < len2; j++) {
        const attr = all[i].attributes[j];
        if (
          attr.name.startsWith("on") ||
          attr.value.startsWith("javascript:") ||
          attr.value.includes("expression(") ||
          attr.value.startsWith("data:") ||
          (attr.name === "src" &&
            attr.value.startsWith("data:image/svg+xml")) ||
          (attr.name === "style" && /url\s*\(/i.test(attr.value))
        ) {
          all[i].removeAttribute(attr.name);
        }
      }
    }
  } catch (err) {
    console.log("error while sanitization" + err);
  }
  return div.innerHTML;
};

export function updateMetaDataCounts(
  metaDataId: number,
  data?: object,
  newLikeCount?: number,
  newViewCount?: number,
  updateLocalStorage?: false,
  likeCount?: number
) {
  try {
    if (data === undefined) {
      var obj = JSON.parse(localStorage?.getItem("bulletinBoard") ?? "");
      data = obj?.bulletinDetails;
    }
    const updatedData = _.cloneDeep(data); // Clone the data to avoid modifying the original object

    const cardData = _.find(updatedData.cardData, { metaDataId });

    if (cardData) {
      if (newLikeCount !== undefined) {
        let count = likeCount
          ? likeCount
          : cardData.customData.applicationLevel.likeCount + newLikeCount;
        _.set(
          cardData,
          "customData.applicationLevel.likeCount",
          count < 0 ? 0 : count
        );
        _.set(cardData, "customData.userLevel.liked", newLikeCount !== -1);
      }

      if (newViewCount !== undefined) {
        _.set(
          cardData,
          "customData.applicationLevel.viewCount",
          cardData.customData.applicationLevel.viewCount + newViewCount
        );
      }

      console.log(
        `Updated metaDataId ${metaDataId} with likeCount=${newLikeCount} and viewCount=${newViewCount}`
      );
    } else {
      console.log(`Error: metaDataId ${metaDataId} not found.`);
    }
    if (updateLocalStorage) {
      if (data) {
        localStorage.setItem(
          "bulletinBoard",
          JSON.stringify({
            ...obj,
            bulletinDetails: updatedData,
          })
        );
      }
    }
    return updatedData;
  } catch (e) {
    // console.log("error updating the metadata counts");
  }
}

export const updateLocalStoragePinData = (props) => {
  const contentName = getContentTypeNamesById2(parseInt(props?.contentType));
  let metaDataId = props?.item?.metaDataId;
  let dataFromLS = JSON.parse(localStorage.getItem(contentName) || "{}");

  if (_.isEmpty(dataFromLS)) return;

  const updateSectionPinData = (data, section) => {
    const sectionPath = `allCardsData.sections.${section}`;
    const index = _.findIndex(_.get(data, `${sectionPath}`, []), {
      metaDataId: metaDataId,
    });

    if (index !== -1) {
      _.set(data, `${sectionPath}[${index}].customData.pinInfo`, {
        ...props?.pinInfo,
      });
    }
  };

  if (!_.get(dataFromLS, "allCardsData.sections.new")) {
    updateSectionPinData(dataFromLS, "all");
    localStorage.setItem(contentName, JSON.stringify(dataFromLS));
  } else {
    const cardData = JSON.parse(localStorage.getItem(contentName) || "{}");
    ["new", "topprevious"].forEach((section) =>
      updateSectionPinData(cardData, section)
    );
    localStorage.setItem(contentName, JSON.stringify(cardData));
  }

  // var obj = JSON.parse(localStorage?.getItem("bulletinBoard") ?? "");
  // let data = obj?.bulletinDetails?.cardData;
  // if (data) {
  //   // const cardData = _.find(data, { metaDataId: metaDataId });

  //   // if (cardData) {
  //   //   cardData.isPinned = props?.pinInfo.pinStatus;
  //   // }
  //   // localStorage.setItem("bulletinBoard", JSON.stringify({
  //   //   ...obj,
  //   //   bulletinDetails: {
  //   //     cardData: data
  //   //   }
  //   // }));
  //   const index = _.findIndex(data, { metaDataId: metaDataId });

  //   if (index !== -1) {
  //     data[index].isPinned = props?.pinInfo.pinStatus; // will be true or false
  //     obj.bulletinDetails.cardData = data;
  //     localStorage.setItem("bulletinBoard", JSON.stringify(obj));

  //   }
  // }
  // props?.actionForDisablingCancel?.(true);
};

export const updateLocalStorageGroupMembers = (props) => {
  const contentName = getContentTypeNamesById2(parseInt(props?.contentType));
  let metaDataId = props?.metaDataId;
  let dataFromLS = JSON.parse(localStorage.getItem(contentName) || "{}");

  if (_.isEmpty(dataFromLS)) return;

  const updateSection = (data, section) => {
    const sectionPath = `allCardsData.sections.${section}`;
    const index = _.findIndex(_.get(data, `${sectionPath}`, []), {
      metaDataId: metaDataId,
    });

    if (index !== -1) {
      _.set(data, `${sectionPath}[${index}].customData.groupInfo`, {
        ...props?.groupInfo,
      });
    }
  };

  if (!_.get(dataFromLS, "allCardsData.sections.new")) {
    updateSection(dataFromLS, "all");
    localStorage.setItem(contentName, JSON.stringify(dataFromLS));
  } else {
    const cardData = JSON.parse(localStorage.getItem(contentName) || "{}");
    ["new", "topprevious"].forEach((section) =>
      updateSection(cardData, section)
    );
    localStorage.setItem(contentName, JSON.stringify(cardData));
  }
};

export const getSelectedCompId = () => {
  let compId = JSON.parse(
    localStorage?.getItem("curUserSelectedCompany") ?? ""
  );
  return compId?.companyId;
};

export const getSelectedBulletinFeatureFlag = () => {
  let comp = JSON.parse(localStorage?.getItem("curUserSelectedCompany") ?? "");
  let bulletinBoardEnabled = comp?.companyFeatureFlags?.filter(
    (i) => i?.featureFlagName === "Bulletin Board"
  )?.[0]?.isEnabled;
  return bulletinBoardEnabled;
};

export const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export function validateEmails(input: string): {
  isValid: boolean;
  validEmails: string[];
} {
  // Split the input by semicolons, optional spaces, and newlines
  const emailList = input.split(/[;\s\n]+/);
  const validEmails: string[] = [];

  for (const email of emailList) {
    // Use a regular expression to check if the email is valid
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email.match(emailPattern)) {
      validEmails.push(email); // Valid email found, add it to the array
    }
  }

  return {
    isValid: validEmails.length === emailList.length, // Return true if all emails are valid
    validEmails: validEmails, // Return an array of valid emails
  };
}
export const checkIfDeviceIsMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const checkIfiOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const getAttachment = async (
  backgroundImageObject,
  metaDataId,
  userInfo,
  contentType = 2,
  isGlobal
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!Array.isArray(backgroundImageObject)) {
      backgroundImageObject = [backgroundImageObject];
      backgroundImageObject[0].fileDetail.fileSize === "" &&
        (backgroundImageObject[0].fileDetail.fileSize = 0);
    }
    postData(
      {
        metaDataId,
        attachments: backgroundImageObject,
      },

      APIEndPoints.DOWNLOAD_IMAGES(
        contentType,
        isGlobal,
        userInfo?.teams?.companyId
      ),
      false,
      true
    )
      .then((response) => {
        resolve(response?.data);
      })

      .catch((error) => console.log("error", error));
  });
};

export const updateViewCounter = (
  cardItem,
  viewCount,
  data,
  updateLS = false,
  contentTypeName = ""
) => {
  if (Object.keys(data).length != 0) {
    if (data?.sections?.new == undefined) {
      const index = data?.sections?.all?.findIndex((obj) => {
        return obj.metaDataId === cardItem.metaDataId;
      });
      if (index !== -1) {
        data.sections.all[index].customData.applicationLevel.viewCount =
          viewCount;
      }

      if (updateLS) {
        let lsData = localStorage.getItem(contentTypeName);
        if (lsData) {
          let Data = JSON.parse(lsData);
          lsData = {
            ...Data,
            allCardsData: data,
          };
          localStorage.setItem(contentTypeName, JSON.stringify(lsData));
        }
      }
      return data;
    } else {
      let cardData = data;
      if (cardData) {
        const new_index = cardData.sections.new.findIndex((obj) => {
          return obj.metaDataId === cardItem.metaDataId;
        });
        if (new_index !== -1) {
          cardData.sections.new[
            new_index
          ].customData.applicationLevel.viewCount = viewCount;
        }
        const tp_index = cardData.sections.topprevious.findIndex((obj) => {
          return obj.metaDataId === cardItem.metaDataId;
        });
        if (tp_index !== -1) {
          cardData.sections.topprevious[
            tp_index
          ].customData.applicationLevel.viewCount = viewCount;
        }
        if (updateLS) {
          let lsData = localStorage.getItem(contentTypeName);
          if (lsData) {
            let Data = JSON.parse(lsData);
            lsData = {
              ...Data,
              allCardsData: data,
            };
            localStorage.setItem(contentTypeName, JSON.stringify(lsData));
          }
        }
        return cardData;
      }
    }
  }
};

export const isElementVisible = (el) => {
  var rect = el?.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const getElementHeightToScroll = () => {
  const contentCurationHeader = document.querySelector(
    "#scrollIdCuration"
  ) as HTMLElement;
  const contentCurationHeaderHeight = contentCurationHeader?.offsetHeight;

  const topbar = document.querySelector("#topbar") as HTMLElement;
  const topbarHeight = topbar?.offsetHeight;

  const totalHeight = contentCurationHeaderHeight + topbarHeight;

  return totalHeight;
};
export const scrollToRequiredFields = async (
  ContentCurationJson,
  errorFields
) => {
  try {
    for (let i = 0; i < ContentCurationJson.FormControls.length; i++) {
      const item = ContentCurationJson.FormControls[i];
      if (Array.isArray(item?.SingleLineControls)) {
        for (let j = 0; j < item?.SingleLineControls?.length; j++) {
          const subItem = item?.SingleLineControls[j];
          if (errorFields[subItem.Name]?.hasAnyError) {
            const totalHeight = await getElementHeightToScroll();
            scrollIntoViewWithOffset(subItem.Name, -(totalHeight + 50));
            break;
          }
        }
      }
      if (errorFields[item.Name]?.hasAnyError) {
        const totalHeight = await getElementHeightToScroll();
        scrollIntoViewWithOffset(item.Name, -(totalHeight + 50));
        break;
      }
    }
  } catch {}
};

//get county name from the below method getCountry

export const getCountry = () => {
  var countries = {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AG: "Antigua and Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Åland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint Barthélemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BQ: "Caribbean Netherlands",
    BR: "Brazil",
    BS: "Bahamas",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocos Islands",
    CD: "Democratic Republic of the Congo",
    CF: "Central African Republic",
    CG: "Republic of the Congo",
    CH: "Switzerland",
    CI: "Ivory Coast",
    CK: "Cook Islands",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cabo Verde",
    CW: "Curaçao",
    CX: "Christmas Island",
    CY: "Cyprus",
    CZ: "Czechia",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DM: "Dominica",
    DO: "Dominican Republic",
    DZ: "Algeria",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    FR: "France",
    GA: "Gabon",
    GB: "United Kingdom",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GS: "South Georgia and the South Sandwich Islands",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory",
    IQ: "Iraq",
    IR: "Iran",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KI: "Kiribati",
    KM: "Comoros",
    KN: "Saint Kitts and Nevis",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Laos",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova",
    ME: "Montenegro",
    MF: "Saint Martin",
    MG: "Madagascar",
    MH: "Marshall Islands",
    MK: "North Macedonia",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolia",
    MO: "Macao",
    MP: "Northern Mariana Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PH: "Philippines",
    PK: "Pakistan",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    PN: "Pitcairn",
    PR: "Puerto Rico",
    PS: "Palestine",
    PT: "Portugal",
    PW: "Palau",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russia",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SD: "Sudan",
    SE: "Sweden",
    SG: "Singapore",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    SI: "Slovenia",
    SJ: "Svalbard and Jan Mayen",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Sudan",
    ST: "Sao Tome and Principe",
    SV: "El Salvador",
    SX: "Sint Maarten",
    SY: "Syria",
    SZ: "Eswatini",
    TC: "Turks and Caicos Islands",
    TD: "Chad",
    TF: "French Southern Territories",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "Timor-Leste",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Turkey",
    TT: "Trinidad and Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tanzania",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "United States Minor Outlying Islands",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VA: "Holy See",
    VC: "Saint Vincent and the Grenadines",
    VE: "Venezuela",
    VG: "Virgin Islands (UK)",
    VI: "Virgin Islands (US)",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis and Futuna",
    WS: "Samoa",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };
  var timezones = {
    "Africa/Abidjan": {
      u: 0,
      c: ["CI", "BF", "GH", "GM", "GN", "ML", "MR", "SH", "SL", "SN", "TG"],
    },
    "Africa/Accra": {
      a: "Africa/Abidjan",
      c: ["GH"],
      r: 1,
    },
    "Africa/Addis_Ababa": {
      a: "Africa/Nairobi",
      c: ["ET"],
      r: 1,
    },
    "Africa/Algiers": {
      u: 60,
      c: ["DZ"],
    },
    "Africa/Asmara": {
      a: "Africa/Nairobi",
      c: ["ER"],
      r: 1,
    },
    "Africa/Asmera": {
      a: "Africa/Nairobi",
      c: ["ER"],
      r: 1,
    },
    "Africa/Bamako": {
      a: "Africa/Abidjan",
      c: ["ML"],
      r: 1,
    },
    "Africa/Bangui": {
      a: "Africa/Lagos",
      c: ["CF"],
      r: 1,
    },
    "Africa/Banjul": {
      a: "Africa/Abidjan",
      c: ["GM"],
      r: 1,
    },
    "Africa/Bissau": {
      u: 0,
      c: ["GW"],
    },
    "Africa/Blantyre": {
      a: "Africa/Maputo",
      c: ["MW"],
      r: 1,
    },
    "Africa/Brazzaville": {
      a: "Africa/Lagos",
      c: ["CG"],
      r: 1,
    },
    "Africa/Bujumbura": {
      a: "Africa/Maputo",
      c: ["BI"],
      r: 1,
    },
    "Africa/Cairo": {
      u: 120,
      c: ["EG"],
    },
    "Africa/Casablanca": {
      u: 60,
      d: 0,
      c: ["MA"],
    },
    "Africa/Ceuta": {
      u: 60,
      d: 120,
      c: ["ES"],
    },
    "Africa/Conakry": {
      a: "Africa/Abidjan",
      c: ["GN"],
      r: 1,
    },
    "Africa/Dakar": {
      a: "Africa/Abidjan",
      c: ["SN"],
      r: 1,
    },
    "Africa/Dar_es_Salaam": {
      a: "Africa/Nairobi",
      c: ["TZ"],
      r: 1,
    },
    "Africa/Djibouti": {
      a: "Africa/Nairobi",
      c: ["DJ"],
      r: 1,
    },
    "Africa/Douala": {
      a: "Africa/Lagos",
      c: ["CM"],
      r: 1,
    },
    "Africa/El_Aaiun": {
      u: 60,
      d: 0,
      c: ["EH"],
    },
    "Africa/Freetown": {
      a: "Africa/Abidjan",
      c: ["SL"],
      r: 1,
    },
    "Africa/Gaborone": {
      a: "Africa/Maputo",
      c: ["BW"],
      r: 1,
    },
    "Africa/Harare": {
      a: "Africa/Maputo",
      c: ["ZW"],
      r: 1,
    },
    "Africa/Johannesburg": {
      u: 120,
      c: ["ZA", "LS", "SZ"],
    },
    "Africa/Juba": {
      u: 120,
      c: ["SS"],
    },
    "Africa/Kampala": {
      a: "Africa/Nairobi",
      c: ["UG"],
      r: 1,
    },
    "Africa/Khartoum": {
      u: 120,
      c: ["SD"],
    },
    "Africa/Kigali": {
      a: "Africa/Maputo",
      c: ["RW"],
      r: 1,
    },
    "Africa/Kinshasa": {
      a: "Africa/Lagos",
      c: ["CD"],
      r: 1,
    },
    "Africa/Lagos": {
      u: 60,
      c: ["NG", "AO", "BJ", "CD", "CF", "CG", "CM", "GA", "GQ", "NE"],
    },
    "Africa/Libreville": {
      a: "Africa/Lagos",
      c: ["GA"],
      r: 1,
    },
    "Africa/Lome": {
      a: "Africa/Abidjan",
      c: ["TG"],
      r: 1,
    },
    "Africa/Luanda": {
      a: "Africa/Lagos",
      c: ["AO"],
      r: 1,
    },
    "Africa/Lubumbashi": {
      a: "Africa/Maputo",
      c: ["CD"],
      r: 1,
    },
    "Africa/Lusaka": {
      a: "Africa/Maputo",
      c: ["ZM"],
      r: 1,
    },
    "Africa/Malabo": {
      a: "Africa/Lagos",
      c: ["GQ"],
      r: 1,
    },
    "Africa/Maputo": {
      u: 120,
      c: ["MZ", "BI", "BW", "CD", "MW", "RW", "ZM", "ZW"],
    },
    "Africa/Maseru": {
      a: "Africa/Johannesburg",
      c: ["LS"],
      r: 1,
    },
    "Africa/Mbabane": {
      a: "Africa/Johannesburg",
      c: ["SZ"],
      r: 1,
    },
    "Africa/Mogadishu": {
      a: "Africa/Nairobi",
      c: ["SO"],
      r: 1,
    },
    "Africa/Monrovia": {
      u: 0,
      c: ["LR"],
    },
    "Africa/Nairobi": {
      u: 180,
      c: ["KE", "DJ", "ER", "ET", "KM", "MG", "SO", "TZ", "UG", "YT"],
    },
    "Africa/Ndjamena": {
      u: 60,
      c: ["TD"],
    },
    "Africa/Niamey": {
      a: "Africa/Lagos",
      c: ["NE"],
      r: 1,
    },
    "Africa/Nouakchott": {
      a: "Africa/Abidjan",
      c: ["MR"],
      r: 1,
    },
    "Africa/Ouagadougou": {
      a: "Africa/Abidjan",
      c: ["BF"],
      r: 1,
    },
    "Africa/Porto-Novo": {
      a: "Africa/Lagos",
      c: ["BJ"],
      r: 1,
    },
    "Africa/Sao_Tome": {
      u: 0,
      c: ["ST"],
    },
    "Africa/Timbuktu": {
      a: "Africa/Abidjan",
      c: ["ML"],
      r: 1,
    },
    "Africa/Tripoli": {
      u: 120,
      c: ["LY"],
    },
    "Africa/Tunis": {
      u: 60,
      c: ["TN"],
    },
    "Africa/Windhoek": {
      u: 120,
      c: ["NA"],
    },
    "America/Adak": {
      u: -600,
      d: -540,
      c: ["US"],
    },
    "America/Anchorage": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Anguilla": {
      a: "America/Puerto_Rico",
      c: ["AI"],
      r: 1,
    },
    "America/Antigua": {
      a: "America/Puerto_Rico",
      c: ["AG"],
      r: 1,
    },
    "America/Araguaina": {
      u: -180,
      c: ["BR"],
    },
    "America/Argentina/Buenos_Aires": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Catamarca": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/ComodRivadavia": {
      a: "America/Argentina/Catamarca",
      r: 1,
    },
    "America/Argentina/Cordoba": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Jujuy": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/La_Rioja": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Mendoza": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Rio_Gallegos": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Salta": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/San_Juan": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/San_Luis": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Tucuman": {
      u: -180,
      c: ["AR"],
    },
    "America/Argentina/Ushuaia": {
      u: -180,
      c: ["AR"],
    },
    "America/Aruba": {
      a: "America/Puerto_Rico",
      c: ["AW"],
      r: 1,
    },
    "America/Asuncion": {
      u: -240,
      d: -180,
      c: ["PY"],
    },
    "America/Atikokan": {
      a: "America/Panama",
      c: ["CA"],
      r: 1,
    },
    "America/Atka": {
      a: "America/Adak",
      r: 1,
    },
    "America/Bahia": {
      u: -180,
      c: ["BR"],
    },
    "America/Bahia_Banderas": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Barbados": {
      u: -240,
      c: ["BB"],
    },
    "America/Belem": {
      u: -180,
      c: ["BR"],
    },
    "America/Belize": {
      u: -360,
      c: ["BZ"],
    },
    "America/Blanc-Sablon": {
      a: "America/Puerto_Rico",
      c: ["CA"],
      r: 1,
    },
    "America/Boa_Vista": {
      u: -240,
      c: ["BR"],
    },
    "America/Bogota": {
      u: -300,
      c: ["CO"],
    },
    "America/Boise": {
      u: -420,
      d: -360,
      c: ["US"],
    },
    "America/Buenos_Aires": {
      a: "America/Argentina/Buenos_Aires",
      r: 1,
    },
    "America/Cambridge_Bay": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "America/Campo_Grande": {
      u: -240,
      c: ["BR"],
    },
    "America/Cancun": {
      u: -300,
      c: ["MX"],
    },
    "America/Caracas": {
      u: -240,
      c: ["VE"],
    },
    "America/Catamarca": {
      a: "America/Argentina/Catamarca",
      r: 1,
    },
    "America/Cayenne": {
      u: -180,
      c: ["GF"],
    },
    "America/Cayman": {
      a: "America/Panama",
      c: ["KY"],
      r: 1,
    },
    "America/Chicago": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Chihuahua": {
      u: -420,
      d: -360,
      c: ["MX"],
    },
    "America/Coral_Harbour": {
      a: "America/Panama",
      c: ["CA"],
      r: 1,
    },
    "America/Cordoba": {
      a: "America/Argentina/Cordoba",
      r: 1,
    },
    "America/Costa_Rica": {
      u: -360,
      c: ["CR"],
    },
    "America/Creston": {
      a: "America/Phoenix",
      c: ["CA"],
      r: 1,
    },
    "America/Cuiaba": {
      u: -240,
      c: ["BR"],
    },
    "America/Curacao": {
      a: "America/Puerto_Rico",
      c: ["CW"],
      r: 1,
    },
    "America/Danmarkshavn": {
      u: 0,
      c: ["GL"],
    },
    "America/Dawson": {
      u: -420,
      c: ["CA"],
    },
    "America/Dawson_Creek": {
      u: -420,
      c: ["CA"],
    },
    "America/Denver": {
      u: -420,
      d: -360,
      c: ["US"],
    },
    "America/Detroit": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Dominica": {
      a: "America/Puerto_Rico",
      c: ["DM"],
      r: 1,
    },
    "America/Edmonton": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "America/Eirunepe": {
      u: -300,
      c: ["BR"],
    },
    "America/El_Salvador": {
      u: -360,
      c: ["SV"],
    },
    "America/Ensenada": {
      a: "America/Tijuana",
      r: 1,
    },
    "America/Fort_Nelson": {
      u: -420,
      c: ["CA"],
    },
    "America/Fort_Wayne": {
      a: "America/Indiana/Indianapolis",
      r: 1,
    },
    "America/Fortaleza": {
      u: -180,
      c: ["BR"],
    },
    "America/Glace_Bay": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Godthab": {
      a: "America/Nuuk",
      r: 1,
    },
    "America/Goose_Bay": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Grand_Turk": {
      u: -300,
      d: -240,
      c: ["TC"],
    },
    "America/Grenada": {
      a: "America/Puerto_Rico",
      c: ["GD"],
      r: 1,
    },
    "America/Guadeloupe": {
      a: "America/Puerto_Rico",
      c: ["GP"],
      r: 1,
    },
    "America/Guatemala": {
      u: -360,
      c: ["GT"],
    },
    "America/Guayaquil": {
      u: -300,
      c: ["EC"],
    },
    "America/Guyana": {
      u: -240,
      c: ["GY"],
    },
    "America/Halifax": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Havana": {
      u: -300,
      d: -240,
      c: ["CU"],
    },
    "America/Hermosillo": {
      u: -420,
      c: ["MX"],
    },
    "America/Indiana/Indianapolis": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Knox": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Indiana/Marengo": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Petersburg": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Tell_City": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Indiana/Vevay": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Vincennes": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indiana/Winamac": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Indianapolis": {
      a: "America/Indiana/Indianapolis",
      r: 1,
    },
    "America/Inuvik": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "America/Iqaluit": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Jamaica": {
      u: -300,
      c: ["JM"],
    },
    "America/Jujuy": {
      a: "America/Argentina/Jujuy",
      r: 1,
    },
    "America/Juneau": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Kentucky/Louisville": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Kentucky/Monticello": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Knox_IN": {
      a: "America/Indiana/Knox",
      r: 1,
    },
    "America/Kralendijk": {
      a: "America/Puerto_Rico",
      c: ["BQ"],
      r: 1,
    },
    "America/La_Paz": {
      u: -240,
      c: ["BO"],
    },
    "America/Lima": {
      u: -300,
      c: ["PE"],
    },
    "America/Los_Angeles": {
      u: -480,
      d: -420,
      c: ["US"],
    },
    "America/Louisville": {
      a: "America/Kentucky/Louisville",
      r: 1,
    },
    "America/Lower_Princes": {
      a: "America/Puerto_Rico",
      c: ["SX"],
      r: 1,
    },
    "America/Maceio": {
      u: -180,
      c: ["BR"],
    },
    "America/Managua": {
      u: -360,
      c: ["NI"],
    },
    "America/Manaus": {
      u: -240,
      c: ["BR"],
    },
    "America/Marigot": {
      a: "America/Puerto_Rico",
      c: ["MF"],
      r: 1,
    },
    "America/Martinique": {
      u: -240,
      c: ["MQ"],
    },
    "America/Matamoros": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Mazatlan": {
      u: -420,
      d: -360,
      c: ["MX"],
    },
    "America/Mendoza": {
      a: "America/Argentina/Mendoza",
      r: 1,
    },
    "America/Menominee": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Merida": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Metlakatla": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Mexico_City": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Miquelon": {
      u: -180,
      d: -120,
      c: ["PM"],
    },
    "America/Moncton": {
      u: -240,
      d: -180,
      c: ["CA"],
    },
    "America/Monterrey": {
      u: -360,
      d: -300,
      c: ["MX"],
    },
    "America/Montevideo": {
      u: -180,
      c: ["UY"],
    },
    "America/Montreal": {
      a: "America/Toronto",
      c: ["CA"],
      r: 1,
    },
    "America/Montserrat": {
      a: "America/Puerto_Rico",
      c: ["MS"],
      r: 1,
    },
    "America/Nassau": {
      a: "America/Toronto",
      c: ["BS"],
      r: 1,
    },
    "America/New_York": {
      u: -300,
      d: -240,
      c: ["US"],
    },
    "America/Nipigon": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Nome": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Noronha": {
      u: -120,
      c: ["BR"],
    },
    "America/North_Dakota/Beulah": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/North_Dakota/Center": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/North_Dakota/New_Salem": {
      u: -360,
      d: -300,
      c: ["US"],
    },
    "America/Nuuk": {
      u: -180,
      d: -120,
      c: ["GL"],
    },
    "America/Ojinaga": {
      u: -420,
      d: -360,
      c: ["MX"],
    },
    "America/Panama": {
      u: -300,
      c: ["PA", "CA", "KY"],
    },
    "America/Pangnirtung": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Paramaribo": {
      u: -180,
      c: ["SR"],
    },
    "America/Phoenix": {
      u: -420,
      c: ["US", "CA"],
    },
    "America/Port-au-Prince": {
      u: -300,
      d: -240,
      c: ["HT"],
    },
    "America/Port_of_Spain": {
      a: "America/Puerto_Rico",
      c: ["TT"],
      r: 1,
    },
    "America/Porto_Acre": {
      a: "America/Rio_Branco",
      r: 1,
    },
    "America/Porto_Velho": {
      u: -240,
      c: ["BR"],
    },
    "America/Puerto_Rico": {
      u: -240,
      c: [
        "PR",
        "AG",
        "CA",
        "AI",
        "AW",
        "BL",
        "BQ",
        "CW",
        "DM",
        "GD",
        "GP",
        "KN",
        "LC",
        "MF",
        "MS",
        "SX",
        "TT",
        "VC",
        "VG",
        "VI",
      ],
    },
    "America/Punta_Arenas": {
      u: -180,
      c: ["CL"],
    },
    "America/Rainy_River": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Rankin_Inlet": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Recife": {
      u: -180,
      c: ["BR"],
    },
    "America/Regina": {
      u: -360,
      c: ["CA"],
    },
    "America/Resolute": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Rio_Branco": {
      u: -300,
      c: ["BR"],
    },
    "America/Rosario": {
      a: "America/Argentina/Cordoba",
      r: 1,
    },
    "America/Santa_Isabel": {
      a: "America/Tijuana",
      r: 1,
    },
    "America/Santarem": {
      u: -180,
      c: ["BR"],
    },
    "America/Santiago": {
      u: -240,
      d: -180,
      c: ["CL"],
    },
    "America/Santo_Domingo": {
      u: -240,
      c: ["DO"],
    },
    "America/Sao_Paulo": {
      u: -180,
      c: ["BR"],
    },
    "America/Scoresbysund": {
      u: -60,
      d: 0,
      c: ["GL"],
    },
    "America/Shiprock": {
      a: "America/Denver",
      r: 1,
    },
    "America/Sitka": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/St_Barthelemy": {
      a: "America/Puerto_Rico",
      c: ["BL"],
      r: 1,
    },
    "America/St_Johns": {
      u: -150,
      d: -90,
      c: ["CA"],
    },
    "America/St_Kitts": {
      a: "America/Puerto_Rico",
      c: ["KN"],
      r: 1,
    },
    "America/St_Lucia": {
      a: "America/Puerto_Rico",
      c: ["LC"],
      r: 1,
    },
    "America/St_Thomas": {
      a: "America/Puerto_Rico",
      c: ["VI"],
      r: 1,
    },
    "America/St_Vincent": {
      a: "America/Puerto_Rico",
      c: ["VC"],
      r: 1,
    },
    "America/Swift_Current": {
      u: -360,
      c: ["CA"],
    },
    "America/Tegucigalpa": {
      u: -360,
      c: ["HN"],
    },
    "America/Thule": {
      u: -240,
      d: -180,
      c: ["GL"],
    },
    "America/Thunder_Bay": {
      u: -300,
      d: -240,
      c: ["CA"],
    },
    "America/Tijuana": {
      u: -480,
      d: -420,
      c: ["MX"],
    },
    "America/Toronto": {
      u: -300,
      d: -240,
      c: ["CA", "BS"],
    },
    "America/Tortola": {
      a: "America/Puerto_Rico",
      c: ["VG"],
      r: 1,
    },
    "America/Vancouver": {
      u: -480,
      d: -420,
      c: ["CA"],
    },
    "America/Virgin": {
      a: "America/Puerto_Rico",
      c: ["VI"],
      r: 1,
    },
    "America/Whitehorse": {
      u: -420,
      c: ["CA"],
    },
    "America/Winnipeg": {
      u: -360,
      d: -300,
      c: ["CA"],
    },
    "America/Yakutat": {
      u: -540,
      d: -480,
      c: ["US"],
    },
    "America/Yellowknife": {
      u: -420,
      d: -360,
      c: ["CA"],
    },
    "Antarctica/Casey": {
      u: 660,
      c: ["AQ"],
    },
    "Antarctica/Davis": {
      u: 420,
      c: ["AQ"],
    },
    "Antarctica/DumontDUrville": {
      a: "Pacific/Port_Moresby",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Macquarie": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Antarctica/Mawson": {
      u: 300,
      c: ["AQ"],
    },
    "Antarctica/McMurdo": {
      a: "Pacific/Auckland",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Palmer": {
      u: -180,
      c: ["AQ"],
    },
    "Antarctica/Rothera": {
      u: -180,
      c: ["AQ"],
    },
    "Antarctica/South_Pole": {
      a: "Pacific/Auckland",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Syowa": {
      a: "Asia/Riyadh",
      c: ["AQ"],
      r: 1,
    },
    "Antarctica/Troll": {
      u: 0,
      d: 120,
      c: ["AQ"],
    },
    "Antarctica/Vostok": {
      u: 360,
      c: ["AQ"],
    },
    "Arctic/Longyearbyen": {
      a: "Europe/Oslo",
      c: ["SJ"],
      r: 1,
    },
    "Asia/Aden": {
      a: "Asia/Riyadh",
      c: ["YE"],
      r: 1,
    },
    "Asia/Almaty": {
      u: 360,
      c: ["KZ"],
    },
    "Asia/Amman": {
      u: 120,
      d: 180,
      c: ["JO"],
    },
    "Asia/Anadyr": {
      u: 720,
      c: ["RU"],
    },
    "Asia/Aqtau": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Aqtobe": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Ashgabat": {
      u: 300,
      c: ["TM"],
    },
    "Asia/Ashkhabad": {
      a: "Asia/Ashgabat",
      r: 1,
    },
    "Asia/Atyrau": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Baghdad": {
      u: 180,
      c: ["IQ"],
    },
    "Asia/Bahrain": {
      a: "Asia/Qatar",
      c: ["BH"],
      r: 1,
    },
    "Asia/Baku": {
      u: 240,
      c: ["AZ"],
    },
    "Asia/Bangkok": {
      u: 420,
      c: ["TH", "KH", "LA", "VN"],
    },
    "Asia/Barnaul": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Beirut": {
      u: 120,
      d: 180,
      c: ["LB"],
    },
    "Asia/Bishkek": {
      u: 360,
      c: ["KG"],
    },
    "Asia/Brunei": {
      u: 480,
      c: ["BN"],
    },
    "Asia/Calcutta": {
      a: "Asia/Kolkata",
      r: 1,
    },
    "Asia/Chita": {
      u: 540,
      c: ["RU"],
    },
    "Asia/Choibalsan": {
      u: 480,
      c: ["MN"],
    },
    "Asia/Chongqing": {
      a: "Asia/Shanghai",
      r: 1,
    },
    "Asia/Chungking": {
      a: "Asia/Shanghai",
      r: 1,
    },
    "Asia/Colombo": {
      u: 330,
      c: ["LK"],
    },
    "Asia/Dacca": {
      a: "Asia/Dhaka",
      r: 1,
    },
    "Asia/Damascus": {
      u: 120,
      d: 180,
      c: ["SY"],
    },
    "Asia/Dhaka": {
      u: 360,
      c: ["BD"],
    },
    "Asia/Dili": {
      u: 540,
      c: ["TL"],
    },
    "Asia/Dubai": {
      u: 240,
      c: ["AE", "OM"],
    },
    "Asia/Dushanbe": {
      u: 300,
      c: ["TJ"],
    },
    "Asia/Famagusta": {
      u: 120,
      d: 180,
      c: ["CY"],
    },
    "Asia/Gaza": {
      u: 120,
      d: 180,
      c: ["PS"],
    },
    "Asia/Harbin": {
      a: "Asia/Shanghai",
      r: 1,
    },
    "Asia/Hebron": {
      u: 120,
      d: 180,
      c: ["PS"],
    },
    "Asia/Ho_Chi_Minh": {
      u: 420,
      c: ["VN"],
    },
    "Asia/Hong_Kong": {
      u: 480,
      c: ["HK"],
    },
    "Asia/Hovd": {
      u: 420,
      c: ["MN"],
    },
    "Asia/Irkutsk": {
      u: 480,
      c: ["RU"],
    },
    "Asia/Istanbul": {
      a: "Europe/Istanbul",
      r: 1,
    },
    "Asia/Jakarta": {
      u: 420,
      c: ["ID"],
    },
    "Asia/Jayapura": {
      u: 540,
      c: ["ID"],
    },
    "Asia/Jerusalem": {
      u: 120,
      d: 180,
      c: ["IL"],
    },
    "Asia/Kabul": {
      u: 270,
      c: ["AF"],
    },
    "Asia/Kamchatka": {
      u: 720,
      c: ["RU"],
    },
    "Asia/Karachi": {
      u: 300,
      c: ["PK"],
    },
    "Asia/Kashgar": {
      a: "Asia/Urumqi",
      r: 1,
    },
    "Asia/Kathmandu": {
      u: 345,
      c: ["NP"],
    },
    "Asia/Katmandu": {
      a: "Asia/Kathmandu",
      r: 1,
    },
    "Asia/Khandyga": {
      u: 540,
      c: ["RU"],
    },
    "Asia/Kolkata": {
      u: 330,
      c: ["IN"],
    },
    "Asia/Krasnoyarsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Kuala_Lumpur": {
      u: 480,
      c: ["MY"],
    },
    "Asia/Kuching": {
      u: 480,
      c: ["MY"],
    },
    "Asia/Kuwait": {
      a: "Asia/Riyadh",
      c: ["KW"],
      r: 1,
    },
    "Asia/Macao": {
      a: "Asia/Macau",
      r: 1,
    },
    "Asia/Macau": {
      u: 480,
      c: ["MO"],
    },
    "Asia/Magadan": {
      u: 660,
      c: ["RU"],
    },
    "Asia/Makassar": {
      u: 480,
      c: ["ID"],
    },
    "Asia/Manila": {
      u: 480,
      c: ["PH"],
    },
    "Asia/Muscat": {
      a: "Asia/Dubai",
      c: ["OM"],
      r: 1,
    },
    "Asia/Nicosia": {
      u: 120,
      d: 180,
      c: ["CY"],
    },
    "Asia/Novokuznetsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Novosibirsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Omsk": {
      u: 360,
      c: ["RU"],
    },
    "Asia/Oral": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Phnom_Penh": {
      a: "Asia/Bangkok",
      c: ["KH"],
      r: 1,
    },
    "Asia/Pontianak": {
      u: 420,
      c: ["ID"],
    },
    "Asia/Pyongyang": {
      u: 540,
      c: ["KP"],
    },
    "Asia/Qatar": {
      u: 180,
      c: ["QA", "BH"],
    },
    "Asia/Qostanay": {
      u: 360,
      c: ["KZ"],
    },
    "Asia/Qyzylorda": {
      u: 300,
      c: ["KZ"],
    },
    "Asia/Rangoon": {
      a: "Asia/Yangon",
      r: 1,
    },
    "Asia/Riyadh": {
      u: 180,
      c: ["SA", "AQ", "KW", "YE"],
    },
    "Asia/Saigon": {
      a: "Asia/Ho_Chi_Minh",
      r: 1,
    },
    "Asia/Sakhalin": {
      u: 660,
      c: ["RU"],
    },
    "Asia/Samarkand": {
      u: 300,
      c: ["UZ"],
    },
    "Asia/Seoul": {
      u: 540,
      c: ["KR"],
    },
    "Asia/Shanghai": {
      u: 480,
      c: ["CN"],
    },
    "Asia/Singapore": {
      u: 480,
      c: ["SG", "MY"],
    },
    "Asia/Srednekolymsk": {
      u: 660,
      c: ["RU"],
    },
    "Asia/Taipei": {
      u: 480,
      c: ["TW"],
    },
    "Asia/Tashkent": {
      u: 300,
      c: ["UZ"],
    },
    "Asia/Tbilisi": {
      u: 240,
      c: ["GE"],
    },
    "Asia/Tehran": {
      u: 210,
      d: 270,
      c: ["IR"],
    },
    "Asia/Tel_Aviv": {
      a: "Asia/Jerusalem",
      r: 1,
    },
    "Asia/Thimbu": {
      a: "Asia/Thimphu",
      r: 1,
    },
    "Asia/Thimphu": {
      u: 360,
      c: ["BT"],
    },
    "Asia/Tokyo": {
      u: 540,
      c: ["JP"],
    },
    "Asia/Tomsk": {
      u: 420,
      c: ["RU"],
    },
    "Asia/Ujung_Pandang": {
      a: "Asia/Makassar",
      r: 1,
    },
    "Asia/Ulaanbaatar": {
      u: 480,
      c: ["MN"],
    },
    "Asia/Ulan_Bator": {
      a: "Asia/Ulaanbaatar",
      r: 1,
    },
    "Asia/Urumqi": {
      u: 360,
      c: ["CN"],
    },
    "Asia/Ust-Nera": {
      u: 600,
      c: ["RU"],
    },
    "Asia/Vientiane": {
      a: "Asia/Bangkok",
      c: ["LA"],
      r: 1,
    },
    "Asia/Vladivostok": {
      u: 600,
      c: ["RU"],
    },
    "Asia/Yakutsk": {
      u: 540,
      c: ["RU"],
    },
    "Asia/Yangon": {
      u: 390,
      c: ["MM"],
    },
    "Asia/Yekaterinburg": {
      u: 300,
      c: ["RU"],
    },
    "Asia/Yerevan": {
      u: 240,
      c: ["AM"],
    },
    "Atlantic/Azores": {
      u: -60,
      d: 0,
      c: ["PT"],
    },
    "Atlantic/Bermuda": {
      u: -240,
      d: -180,
      c: ["BM"],
    },
    "Atlantic/Canary": {
      u: 0,
      d: 60,
      c: ["ES"],
    },
    "Atlantic/Cape_Verde": {
      u: -60,
      c: ["CV"],
    },
    "Atlantic/Faeroe": {
      a: "Atlantic/Faroe",
      r: 1,
    },
    "Atlantic/Faroe": {
      u: 0,
      d: 60,
      c: ["FO"],
    },
    "Atlantic/Jan_Mayen": {
      a: "Europe/Oslo",
      c: ["SJ"],
      r: 1,
    },
    "Atlantic/Madeira": {
      u: 0,
      d: 60,
      c: ["PT"],
    },
    "Atlantic/Reykjavik": {
      u: 0,
      c: ["IS"],
    },
    "Atlantic/South_Georgia": {
      u: -120,
      c: ["GS"],
    },
    "Atlantic/St_Helena": {
      a: "Africa/Abidjan",
      c: ["SH"],
      r: 1,
    },
    "Atlantic/Stanley": {
      u: -180,
      c: ["FK"],
    },
    "Australia/ACT": {
      a: "Australia/Sydney",
      r: 1,
    },
    "Australia/Adelaide": {
      u: 570,
      d: 630,
      c: ["AU"],
    },
    "Australia/Brisbane": {
      u: 600,
      c: ["AU"],
    },
    "Australia/Broken_Hill": {
      u: 570,
      d: 630,
      c: ["AU"],
    },
    "Australia/Canberra": {
      a: "Australia/Sydney",
      r: 1,
    },
    "Australia/Currie": {
      a: "Australia/Hobart",
      r: 1,
    },
    "Australia/Darwin": {
      u: 570,
      c: ["AU"],
    },
    "Australia/Eucla": {
      u: 525,
      c: ["AU"],
    },
    "Australia/Hobart": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Australia/LHI": {
      a: "Australia/Lord_Howe",
      r: 1,
    },
    "Australia/Lindeman": {
      u: 600,
      c: ["AU"],
    },
    "Australia/Lord_Howe": {
      u: 630,
      d: 660,
      c: ["AU"],
    },
    "Australia/Melbourne": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Australia/NSW": {
      a: "Australia/Sydney",
      r: 1,
    },
    "Australia/North": {
      a: "Australia/Darwin",
      r: 1,
    },
    "Australia/Perth": {
      u: 480,
      c: ["AU"],
    },
    "Australia/Queensland": {
      a: "Australia/Brisbane",
      r: 1,
    },
    "Australia/South": {
      a: "Australia/Adelaide",
      r: 1,
    },
    "Australia/Sydney": {
      u: 600,
      d: 660,
      c: ["AU"],
    },
    "Australia/Tasmania": {
      a: "Australia/Hobart",
      r: 1,
    },
    "Australia/Victoria": {
      a: "Australia/Melbourne",
      r: 1,
    },
    "Australia/West": {
      a: "Australia/Perth",
      r: 1,
    },
    "Australia/Yancowinna": {
      a: "Australia/Broken_Hill",
      r: 1,
    },
    "Brazil/Acre": {
      a: "America/Rio_Branco",
      r: 1,
    },
    "Brazil/DeNoronha": {
      a: "America/Noronha",
      r: 1,
    },
    "Brazil/East": {
      a: "America/Sao_Paulo",
      r: 1,
    },
    "Brazil/West": {
      a: "America/Manaus",
      r: 1,
    },
    CET: {
      u: 60,
      d: 120,
    },
    CST6CDT: {
      u: -360,
      d: -300,
    },
    "Canada/Atlantic": {
      a: "America/Halifax",
      r: 1,
    },
    "Canada/Central": {
      a: "America/Winnipeg",
      r: 1,
    },
    "Canada/Eastern": {
      a: "America/Toronto",
      c: ["CA"],
      r: 1,
    },
    "Canada/Mountain": {
      a: "America/Edmonton",
      r: 1,
    },
    "Canada/Newfoundland": {
      a: "America/St_Johns",
      r: 1,
    },
    "Canada/Pacific": {
      a: "America/Vancouver",
      r: 1,
    },
    "Canada/Saskatchewan": {
      a: "America/Regina",
      r: 1,
    },
    "Canada/Yukon": {
      a: "America/Whitehorse",
      r: 1,
    },
    "Chile/Continental": {
      a: "America/Santiago",
      r: 1,
    },
    "Chile/EasterIsland": {
      a: "Pacific/Easter",
      r: 1,
    },
    Cuba: {
      a: "America/Havana",
      r: 1,
    },
    EET: {
      u: 120,
      d: 180,
    },
    EST: {
      u: -300,
    },
    EST5EDT: {
      u: -300,
      d: -240,
    },
    Egypt: {
      a: "Africa/Cairo",
      r: 1,
    },
    Eire: {
      a: "Europe/Dublin",
      r: 1,
    },
    "Etc/GMT": {
      u: 0,
    },
    "Etc/GMT+0": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/GMT+1": {
      u: -60,
    },
    "Etc/GMT+10": {
      u: -600,
    },
    "Etc/GMT+11": {
      u: -660,
    },
    "Etc/GMT+12": {
      u: -720,
    },
    "Etc/GMT+2": {
      u: -120,
    },
    "Etc/GMT+3": {
      u: -180,
    },
    "Etc/GMT+4": {
      u: -240,
    },
    "Etc/GMT+5": {
      u: -300,
    },
    "Etc/GMT+6": {
      u: -360,
    },
    "Etc/GMT+7": {
      u: -420,
    },
    "Etc/GMT+8": {
      u: -480,
    },
    "Etc/GMT+9": {
      u: -540,
    },
    "Etc/GMT-0": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/GMT-1": {
      u: 60,
    },
    "Etc/GMT-10": {
      u: 600,
    },
    "Etc/GMT-11": {
      u: 660,
    },
    "Etc/GMT-12": {
      u: 720,
    },
    "Etc/GMT-13": {
      u: 780,
    },
    "Etc/GMT-14": {
      u: 840,
    },
    "Etc/GMT-2": {
      u: 120,
    },
    "Etc/GMT-3": {
      u: 180,
    },
    "Etc/GMT-4": {
      u: 240,
    },
    "Etc/GMT-5": {
      u: 300,
    },
    "Etc/GMT-6": {
      u: 360,
    },
    "Etc/GMT-7": {
      u: 420,
    },
    "Etc/GMT-8": {
      u: 480,
    },
    "Etc/GMT-9": {
      u: 540,
    },
    "Etc/GMT0": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/Greenwich": {
      a: "Etc/GMT",
      r: 1,
    },
    "Etc/UCT": {
      a: "Etc/UTC",
      r: 1,
    },
    "Etc/UTC": {
      u: 0,
    },
    "Etc/Universal": {
      a: "Etc/UTC",
      r: 1,
    },
    "Etc/Zulu": {
      a: "Etc/UTC",
      r: 1,
    },
    "Europe/Amsterdam": {
      u: 60,
      d: 120,
      c: ["NL"],
    },
    "Europe/Andorra": {
      u: 60,
      d: 120,
      c: ["AD"],
    },
    "Europe/Astrakhan": {
      u: 240,
      c: ["RU"],
    },
    "Europe/Athens": {
      u: 120,
      d: 180,
      c: ["GR"],
    },
    "Europe/Belfast": {
      a: "Europe/London",
      c: ["GB"],
      r: 1,
    },
    "Europe/Belgrade": {
      u: 60,
      d: 120,
      c: ["RS", "BA", "HR", "ME", "MK", "SI"],
    },
    "Europe/Berlin": {
      u: 60,
      d: 120,
      c: ["DE"],
    },
    "Europe/Bratislava": {
      a: "Europe/Prague",
      c: ["SK"],
      r: 1,
    },
    "Europe/Brussels": {
      u: 60,
      d: 120,
      c: ["BE"],
    },
    "Europe/Bucharest": {
      u: 120,
      d: 180,
      c: ["RO"],
    },
    "Europe/Budapest": {
      u: 60,
      d: 120,
      c: ["HU"],
    },
    "Europe/Busingen": {
      a: "Europe/Zurich",
      c: ["DE"],
      r: 1,
    },
    "Europe/Chisinau": {
      u: 120,
      d: 180,
      c: ["MD"],
    },
    "Europe/Copenhagen": {
      u: 60,
      d: 120,
      c: ["DK"],
    },
    "Europe/Dublin": {
      u: 60,
      d: 0,
      c: ["IE"],
    },
    "Europe/Gibraltar": {
      u: 60,
      d: 120,
      c: ["GI"],
    },
    "Europe/Guernsey": {
      a: "Europe/London",
      c: ["GG"],
      r: 1,
    },
    "Europe/Helsinki": {
      u: 120,
      d: 180,
      c: ["FI", "AX"],
    },
    "Europe/Isle_of_Man": {
      a: "Europe/London",
      c: ["IM"],
      r: 1,
    },
    "Europe/Istanbul": {
      u: 180,
      c: ["TR"],
    },
    "Europe/Jersey": {
      a: "Europe/London",
      c: ["JE"],
      r: 1,
    },
    "Europe/Kaliningrad": {
      u: 120,
      c: ["RU"],
    },
    "Europe/Kiev": {
      u: 120,
      d: 180,
      c: ["UA"],
    },
    "Europe/Kirov": {
      u: 180,
      c: ["RU"],
    },
    "Europe/Lisbon": {
      u: 0,
      d: 60,
      c: ["PT"],
    },
    "Europe/Ljubljana": {
      a: "Europe/Belgrade",
      c: ["SI"],
      r: 1,
    },
    "Europe/London": {
      u: 0,
      d: 60,
      c: ["GB", "GG", "IM", "JE"],
    },
    "Europe/Luxembourg": {
      u: 60,
      d: 120,
      c: ["LU"],
    },
    "Europe/Madrid": {
      u: 60,
      d: 120,
      c: ["ES"],
    },
    "Europe/Malta": {
      u: 60,
      d: 120,
      c: ["MT"],
    },
    "Europe/Mariehamn": {
      a: "Europe/Helsinki",
      c: ["AX"],
      r: 1,
    },
    "Europe/Minsk": {
      u: 180,
      c: ["BY"],
    },
    "Europe/Monaco": {
      u: 60,
      d: 120,
      c: ["MC"],
    },
    "Europe/Moscow": {
      u: 180,
      c: ["RU"],
    },
    "Europe/Nicosia": {
      a: "Asia/Nicosia",
      r: 1,
    },
    "Europe/Oslo": {
      u: 60,
      d: 120,
      c: ["NO", "SJ", "BV"],
    },
    "Europe/Paris": {
      u: 60,
      d: 120,
      c: ["FR"],
    },
    "Europe/Podgorica": {
      a: "Europe/Belgrade",
      c: ["ME"],
      r: 1,
    },
    "Europe/Prague": {
      u: 60,
      d: 120,
      c: ["CZ", "SK"],
    },
    "Europe/Riga": {
      u: 120,
      d: 180,
      c: ["LV"],
    },
    "Europe/Rome": {
      u: 60,
      d: 120,
      c: ["IT", "SM", "VA"],
    },
    "Europe/Samara": {
      u: 240,
      c: ["RU"],
    },
    "Europe/San_Marino": {
      a: "Europe/Rome",
      c: ["SM"],
      r: 1,
    },
    "Europe/Sarajevo": {
      a: "Europe/Belgrade",
      c: ["BA"],
      r: 1,
    },
    "Europe/Saratov": {
      u: 240,
      c: ["RU"],
    },
    "Europe/Simferopol": {
      u: 180,
      c: ["RU", "UA"],
    },
    "Europe/Skopje": {
      a: "Europe/Belgrade",
      c: ["MK"],
      r: 1,
    },
    "Europe/Sofia": {
      u: 120,
      d: 180,
      c: ["BG"],
    },
    "Europe/Stockholm": {
      u: 60,
      d: 120,
      c: ["SE"],
    },
    "Europe/Tallinn": {
      u: 120,
      d: 180,
      c: ["EE"],
    },
    "Europe/Tirane": {
      u: 60,
      d: 120,
      c: ["AL"],
    },
    "Europe/Tiraspol": {
      a: "Europe/Chisinau",
      r: 1,
    },
    "Europe/Ulyanovsk": {
      u: 240,
      c: ["RU"],
    },
    "Europe/Uzhgorod": {
      u: 120,
      d: 180,
      c: ["UA"],
    },
    "Europe/Vaduz": {
      a: "Europe/Zurich",
      c: ["LI"],
      r: 1,
    },
    "Europe/Vatican": {
      a: "Europe/Rome",
      c: ["VA"],
      r: 1,
    },
    "Europe/Vienna": {
      u: 60,
      d: 120,
      c: ["AT"],
    },
    "Europe/Vilnius": {
      u: 120,
      d: 180,
      c: ["LT"],
    },
    "Europe/Volgograd": {
      u: 180,
      c: ["RU"],
    },
    "Europe/Warsaw": {
      u: 60,
      d: 120,
      c: ["PL"],
    },
    "Europe/Zagreb": {
      a: "Europe/Belgrade",
      c: ["HR"],
      r: 1,
    },
    "Europe/Zaporozhye": {
      u: 120,
      d: 180,
      c: ["UA"],
    },
    "Europe/Zurich": {
      u: 60,
      d: 120,
      c: ["CH", "DE", "LI"],
    },
    Factory: {
      u: 0,
    },
    GB: {
      a: "Europe/London",
      c: ["GB"],
      r: 1,
    },
    "GB-Eire": {
      a: "Europe/London",
      c: ["GB"],
      r: 1,
    },
    GMT: {
      a: "Etc/GMT",
      r: 1,
    },
    "GMT+0": {
      a: "Etc/GMT",
      r: 1,
    },
    "GMT-0": {
      a: "Etc/GMT",
      r: 1,
    },
    GMT0: {
      a: "Etc/GMT",
      r: 1,
    },
    Greenwich: {
      a: "Etc/GMT",
      r: 1,
    },
    HST: {
      u: -600,
    },
    Hongkong: {
      a: "Asia/Hong_Kong",
      r: 1,
    },
    Iceland: {
      a: "Atlantic/Reykjavik",
      r: 1,
    },
    "Indian/Antananarivo": {
      a: "Africa/Nairobi",
      c: ["MG"],
      r: 1,
    },
    "Indian/Chagos": {
      u: 360,
      c: ["IO"],
    },
    "Indian/Christmas": {
      u: 420,
      c: ["CX"],
    },
    "Indian/Cocos": {
      u: 390,
      c: ["CC"],
    },
    "Indian/Comoro": {
      a: "Africa/Nairobi",
      c: ["KM"],
      r: 1,
    },
    "Indian/Kerguelen": {
      u: 300,
      c: ["TF", "HM"],
    },
    "Indian/Mahe": {
      u: 240,
      c: ["SC"],
    },
    "Indian/Maldives": {
      u: 300,
      c: ["MV"],
    },
    "Indian/Mauritius": {
      u: 240,
      c: ["MU"],
    },
    "Indian/Mayotte": {
      a: "Africa/Nairobi",
      c: ["YT"],
      r: 1,
    },
    "Indian/Reunion": {
      u: 240,
      c: ["RE", "TF"],
    },
    Iran: {
      a: "Asia/Tehran",
      r: 1,
    },
    Israel: {
      a: "Asia/Jerusalem",
      r: 1,
    },
    Jamaica: {
      a: "America/Jamaica",
      r: 1,
    },
    Japan: {
      a: "Asia/Tokyo",
      r: 1,
    },
    Kwajalein: {
      a: "Pacific/Kwajalein",
      r: 1,
    },
    Libya: {
      a: "Africa/Tripoli",
      r: 1,
    },
    MET: {
      u: 60,
      d: 120,
    },
    MST: {
      u: -420,
    },
    MST7MDT: {
      u: -420,
      d: -360,
    },
    "Mexico/BajaNorte": {
      a: "America/Tijuana",
      r: 1,
    },
    "Mexico/BajaSur": {
      a: "America/Mazatlan",
      r: 1,
    },
    "Mexico/General": {
      a: "America/Mexico_City",
      r: 1,
    },
    NZ: {
      a: "Pacific/Auckland",
      c: ["NZ"],
      r: 1,
    },
    "NZ-CHAT": {
      a: "Pacific/Chatham",
      r: 1,
    },
    Navajo: {
      a: "America/Denver",
      r: 1,
    },
    PRC: {
      a: "Asia/Shanghai",
      r: 1,
    },
    PST8PDT: {
      u: -480,
      d: -420,
    },
    "Pacific/Apia": {
      u: 780,
      c: ["WS"],
    },
    "Pacific/Auckland": {
      u: 720,
      d: 780,
      c: ["NZ", "AQ"],
    },
    "Pacific/Bougainville": {
      u: 660,
      c: ["PG"],
    },
    "Pacific/Chatham": {
      u: 765,
      d: 825,
      c: ["NZ"],
    },
    "Pacific/Chuuk": {
      u: 600,
      c: ["FM"],
    },
    "Pacific/Easter": {
      u: -360,
      d: -300,
      c: ["CL"],
    },
    "Pacific/Efate": {
      u: 660,
      c: ["VU"],
    },
    "Pacific/Enderbury": {
      a: "Pacific/Kanton",
      r: 1,
    },
    "Pacific/Fakaofo": {
      u: 780,
      c: ["TK"],
    },
    "Pacific/Fiji": {
      u: 720,
      d: 780,
      c: ["FJ"],
    },
    "Pacific/Funafuti": {
      u: 720,
      c: ["TV"],
    },
    "Pacific/Galapagos": {
      u: -360,
      c: ["EC"],
    },
    "Pacific/Gambier": {
      u: -540,
      c: ["PF"],
    },
    "Pacific/Guadalcanal": {
      u: 660,
      c: ["SB"],
    },
    "Pacific/Guam": {
      u: 600,
      c: ["GU", "MP"],
    },
    "Pacific/Honolulu": {
      u: -600,
      c: ["US", "UM"],
    },
    "Pacific/Johnston": {
      a: "Pacific/Honolulu",
      c: ["UM"],
      r: 1,
    },
    "Pacific/Kanton": {
      u: 780,
      c: ["KI"],
    },
    "Pacific/Kiritimati": {
      u: 840,
      c: ["KI"],
    },
    "Pacific/Kosrae": {
      u: 660,
      c: ["FM"],
    },
    "Pacific/Kwajalein": {
      u: 720,
      c: ["MH"],
    },
    "Pacific/Majuro": {
      u: 720,
      c: ["MH"],
    },
    "Pacific/Marquesas": {
      u: -510,
      c: ["PF"],
    },
    "Pacific/Midway": {
      a: "Pacific/Pago_Pago",
      c: ["UM"],
      r: 1,
    },
    "Pacific/Nauru": {
      u: 720,
      c: ["NR"],
    },
    "Pacific/Niue": {
      u: -660,
      c: ["NU"],
    },
    "Pacific/Norfolk": {
      u: 660,
      d: 720,
      c: ["NF"],
    },
    "Pacific/Noumea": {
      u: 660,
      c: ["NC"],
    },
    "Pacific/Pago_Pago": {
      u: -660,
      c: ["AS", "UM"],
    },
    "Pacific/Palau": {
      u: 540,
      c: ["PW"],
    },
    "Pacific/Pitcairn": {
      u: -480,
      c: ["PN"],
    },
    "Pacific/Pohnpei": {
      u: 660,
      c: ["FM"],
    },
    "Pacific/Ponape": {
      a: "Pacific/Pohnpei",
      r: 1,
    },
    "Pacific/Port_Moresby": {
      u: 600,
      c: ["PG", "AQ"],
    },
    "Pacific/Rarotonga": {
      u: -600,
      c: ["CK"],
    },
    "Pacific/Saipan": {
      a: "Pacific/Guam",
      c: ["MP"],
      r: 1,
    },
    "Pacific/Samoa": {
      a: "Pacific/Pago_Pago",
      c: ["WS"],
      r: 1,
    },
    "Pacific/Tahiti": {
      u: -600,
      c: ["PF"],
    },
    "Pacific/Tarawa": {
      u: 720,
      c: ["KI"],
    },
    "Pacific/Tongatapu": {
      u: 780,
      c: ["TO"],
    },
    "Pacific/Truk": {
      a: "Pacific/Chuuk",
      r: 1,
    },
    "Pacific/Wake": {
      u: 720,
      c: ["UM"],
    },
    "Pacific/Wallis": {
      u: 720,
      c: ["WF"],
    },
    "Pacific/Yap": {
      a: "Pacific/Chuuk",
      r: 1,
    },
    Poland: {
      a: "Europe/Warsaw",
      r: 1,
    },
    Portugal: {
      a: "Europe/Lisbon",
      r: 1,
    },
    ROC: {
      a: "Asia/Taipei",
      r: 1,
    },
    ROK: {
      a: "Asia/Seoul",
      r: 1,
    },
    Singapore: {
      a: "Asia/Singapore",
      c: ["SG"],
      r: 1,
    },
    Turkey: {
      a: "Europe/Istanbul",
      r: 1,
    },
    UCT: {
      a: "Etc/UTC",
      r: 1,
    },
    "US/Alaska": {
      a: "America/Anchorage",
      r: 1,
    },
    "US/Aleutian": {
      a: "America/Adak",
      r: 1,
    },
    "US/Arizona": {
      a: "America/Phoenix",
      c: ["US"],
      r: 1,
    },
    "US/Central": {
      a: "America/Chicago",
      r: 1,
    },
    "US/East-Indiana": {
      a: "America/Indiana/Indianapolis",
      r: 1,
    },
    "US/Eastern": {
      a: "America/New_York",
      r: 1,
    },
    "US/Hawaii": {
      a: "Pacific/Honolulu",
      c: ["US"],
      r: 1,
    },
    "US/Indiana-Starke": {
      a: "America/Indiana/Knox",
      r: 1,
    },
    "US/Michigan": {
      a: "America/Detroit",
      r: 1,
    },
    "US/Mountain": {
      a: "America/Denver",
      r: 1,
    },
    "US/Pacific": {
      a: "America/Los_Angeles",
      r: 1,
    },
    "US/Samoa": {
      a: "Pacific/Pago_Pago",
      c: ["WS"],
      r: 1,
    },
    UTC: {
      a: "Etc/UTC",
      r: 1,
    },
    Universal: {
      a: "Etc/UTC",
      r: 1,
    },
    "W-SU": {
      a: "Europe/Moscow",
      r: 1,
    },
    WET: {
      u: 0,
      d: 60,
    },
    Zulu: {
      a: "Etc/UTC",
      r: 1,
    },
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezone === "" || !timezone) {
    return null;
  }

  if (timezones[timezone].c) {
    const _country = timezones[timezone].c[0];
    const country = countries[_country];
    return {
      userCountry: country,
      allCountries: countries,
    };
  } else {
    if (timezones[timezone]?.a) {
      const _country = timezones[timezones[timezone].a].c[0];
      const country = countries[_country];
      return {
        userCountry: country,
        allCountries: countries,
      };
    }
  }
};

export const isTokenExpired = (token) => {
  try {
    // JWT tokens are in 3 parts, separated by '.'
    const [header, payload, signature] = token.split(".");
    // The payload is base64 encoded, so decode it
    const decodedPayload = atob(payload);
    // Parse the payload as JSON
    const payloadObj = JSON.parse(decodedPayload);
    // Get the current time
    const currentTime = Math.floor(new Date().getTime() / 1000);
    // Check if the token has expired
    return currentTime > payloadObj.exp;
  } catch (e) {
    console.error("Error checking token expiration: ", e);
    return false;
  }
};

export const getFormattedTimestamp = () => {
  const date = new Date();
  const originalHours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = originalHours >= 12 ? "PM" : "AM";
  const transformedHours = originalHours % 12 || 12; // transformedHours is either non-zero remainder or 12
  const strHours =
    transformedHours < 10
      ? "0" + transformedHours
      : transformedHours.toString(); // ensure 2 digits
  const strMinutes = minutes < 10 ? "0" + minutes : minutes.toString(); // ensure 2 digits
  const strTime = `${strHours}:${strMinutes} ${ampm}`;
  return strTime;
};

export const isBase64 = (str) => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const checkAndDecode = (OutputOriginalPrompt) => {
  if (isBase64(OutputOriginalPrompt)) {
    return atob(OutputOriginalPrompt);
  }
  return OutputOriginalPrompt;
};
