/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./multiTypeDataContainer.css";
import CardExampleSize from "../../commonui/card/Card";
//import DetailsViewPage from "../components/detailsviewpage/detailsViewPage";
import APIEndPoints from "../../utility/apiendpoints";
import { getDataById, postData } from "../../services/customApis";
import PageNotFound from "../../commonui/pagenotfound/pageNotFound";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { useMenuContext } from "../../context/menucontext/menuContext";
import { isVisible } from "../../utility/menuscroll";
import Constant from "../../utility/constants";
import LoadMore from "../../commonui/loadmorebtn/loadmore";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { useNavigate } from "react-router-dom";
import { app } from "@microsoft/teams-js";
import { UseContentPaginateConfigContext } from "../../context/contentpaginateconfigcontext/contentPaginateConfigContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { scrollIntoViewWithOffset } from "../../utility/menuscroll";
import ComingSoon from "../../commonui/comminsoon/comingSoon";
import { useHamburgerMenuContext } from "../../context/menucontext/menuContext";
import { TrackEvent } from "../../services/appInsights";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import {
  getContentTypeNamesById2,
  getContentTypePathsById,
  openPdfInNewTab,
  getUrlParams,
  downloadPdf,
  updateViewCounter,
} from "../../utility/utility";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import { GrClose } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import DeepLinkPopup from "../../components/polls/pollsCurationForm/pollPopup";
import _ from "lodash";

export const MultiTypeDataContainer = (props) => {
  const location = useLocation();
  const [allCardsData, setAllCardsData] = useState({});
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [cardFetchDetails, setCardFetchDetails] = useState(props.cardFetchObj);
  const [isSectionAvailable, setSectionAvailability] = useState(
    props.isSectionAvailable
  );
  const { menuState } = useMenuContext();
  const { userInfo } = UseUserInfoContext();
  const { contentPaginateConfig } = UseContentPaginateConfigContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [detailsPageAccess, setDetailsPageAccess] = useState(true);
  const [contentTypeLevelFlags, setContentTypeLevelFlags] = useState(undefined);
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [changeViewCount, setChangeViewCount] = useState(0);
  const navigate = useNavigate();
  const [pdfRender, setPdfRender] = useState(false);

  const getData = async (type = undefined, cardFetch) => {
    !type && setIsLoading(true);
    // let params = {
    //   pageLength: null,
    //   pageNumber: null,
    //   subContentType: null,
    // };
    let params = {
      pageLength: 6,
      pageNumber: 1,
      subContentType: null,
    };

    // for first api call, below code is not necessary, so checking with type parameter,
    // if type is undefined below code wont run
    if (type) {
      params = {
        pageLength: cardFetchDetails[type].pageLength,
        pageNumber: cardFetchDetails[type].page,
        subContentType: cardFetchDetails[type].subContentType,
      };
    }
    // making api call with params
    await postData(
      params,
      APIEndPoints.CONTENT_DATASET(
        props.contentTypeId,
        userInfo?.teams?.companyId
      )
    )
      .then((cardData) => {
        //console.log(cardData?.data?.sections?.all.length);
        if (typeof cardData.data.contentType === "object") {
          const newData =
            cardData.data.resultData == undefined
              ? cardData?.data
              : cardData?.data?.resultData;

          if (type) {
            //concatenate the data
            newData.sections = {
              ...allCardsData["sections"],
              [type]: allCardsData["sections"][type].concat(
                newData?.sections[type]
              ),
            };

            // get rowIndex and totalCount for hasMoreData and set details for page states
            const {
              customData: {
                pageinfo: { rowIndex, totalCount } = {
                  rowIndex: 0,
                  totalCount: 0,
                },
              } = { pageinfo: { rowIndex: 0, totalCount: 0 } },
            } = newData?.sections?.[type]?.at(-1) || {};

            setCardFetchDetails((prevData) => {
              return {
                ...prevData,
                [type]: {
                  ...prevData[type],
                  isFetching: false,
                  page:
                    type == undefined
                      ? prevData[type].page
                      : prevData[type].page + 1,
                  hasMoreData: rowIndex !== totalCount,
                },
              };
            });
          } else {
            //let contentTypes = ["new", "topprevious"];
            let contentTypes = Object.keys(newData.sections);
            contentTypes.forEach((type) => {
              if (type !== "default") {
                if (newData?.sections?.[type].length < 1) return;

                const {
                  customData: {
                    pageinfo: { rowIndex, totalCount } = {
                      rowIndex: 0,
                      totalCount: 0,
                    },
                  } = { pageinfo: { rowIndex: 0, totalCount: 0 } },
                } = newData?.sections?.[type]?.at(-1) || {};

                setCardFetchDetails((previousData) => {
                  return {
                    ...previousData,
                    [type]: {
                      ...previousData[type],
                      isFetching: false,
                      // page: tilesFetchDetails[type].page + 1,
                      hasMoreData: rowIndex !== totalCount,
                    },
                  };
                });
              }
            });
          }

          setAllCardsData(newData);
          setIsLoading(false);

          (cardFetchDetails.new.config.length < 1 ||
            cardFetchDetails.topprevious.config.length < 1 ||
            !type) &&
            getConfig(cardData.data.contentType, newData?.sections, cardFetch);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied."
        ) {
          setAccessDenied(true);
        } else {
          setErrorApiCall(true);
        }
        //alert(error)
      });
  };
  const handleAPIError = (isAccessDenied) => {
    if (isAccessDenied) {
      setAccessDenied(true);
    } else {
      setErrorApiCall(true);
    }
  };
  const getConfig = async (configData, items, cardFetch) => {
    //console.log({ items });
    if (configData !== undefined) {
      if (configData[0].contentTypeId === props.contentTypeId) {
        // save contentType names
        setCardFetchDetails((newData) => {
          let prevData = {};
          if (cardFetch) {
            prevData = props.cardFetchObj;
          } else {
            prevData = newData;
          }
          //this few lines of code needs to be removed once backend changes the section for tax alert
          let topPrev =
            items?.topprevious == undefined
              ? items?.topPrevious
              : items?.topprevious;
          return {
            ...prevData,
            new: {
              ...prevData.new,
              subContentType:
                configData[0].subContentTypes[0].subContentTypeName,
              subContentTypeDisplayName:
                configData[0].subContentTypes[0].subContentTypeDisplayName,
              config: configData[0].subContentTypes[0]?.contentCategories,
              hasMoreData: items?.new?.length < 6 ? false : true,
            },
            topprevious: {
              ...prevData.topprevious,
              subContentType:
                configData[0].subContentTypes[1].subContentTypeName,
              subContentTypeDisplayName:
                configData[0].subContentTypes[1].subContentTypeDisplayName,
              config: configData[0].subContentTypes[1].contentCategories,
              hasMoreData: items?.topprevious?.length < 6 ? false : true,
            },
            contentType: configData[0].contentTypeId,
          };
        });

        setContentTypeLevelFlags(configData[0]?.contentCategories);
        props.setNewButtonConfig(
          configData[0]?.contentCategories?.[1]?.featureFlagsDetails?.addNew
        );
        props.setShortcutButtonConfig(
          configData[0]?.contentCategories?.[1]?.featureFlagsDetails
            ?.myShortcuts
        );
        props.setLibraryButtonConfig(
          userInfo?.teams?.isGlobalContentCompany == true
            ? configData[0]?.contentCategories?.[0]?.featureFlagsDetails
                ?.library
            : configData[0]?.contentCategories?.[1]?.featureFlagsDetails
                ?.library
        );
        setDetailsPageAccess(configData[0]?.contentCategories);
      }
    }
  };

  const getCardLimit = () => {
    getData(
      APIEndPoints.CARD_LIMIT(props.contentTypeId, userInfo.teams.companyId)
    )
      .then((res) => {
        let data = Object.fromEntries(
          res.data.map((item) => [item.subContentTypeName, item.cardLimit])
        );
        setCardFetchDetails((prevData) => {
          return {
            ...prevData,
            new: {
              ...prevData.new,
              pageLength: data["New"] / contentPaginateConfig.limit,
            },
            topprevious: {
              ...prevData.topprevious,
              pageLength: data["Top Previous"] / contentPaginateConfig.limit,
            },
          };
        });
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (
      !isApiCalled &&
      (menuState.submenu.name === props.name ||
        menuState.submenu.name === "Overview" ||
        menuState.menu.name === props.name ||
        (menuState.menu.name === "Search libraries" &&
          menuState.submenu.name === "")) &&
      !allCardsData["sections"]?.new
    ) {
      setIsLoading(true);
      let subMenuItemId =
        userInfo?.teams?.featureFlagsObj[props.name]?.subMenuItemId;
      getDataById(
        "",
        APIEndPoints.GET_PAGE_LEVEL_FLAGS(
          userInfo?.teams?.companyId,
          subMenuItemId
        )
      )
        .then(async (res) => {
          getUrlParams("backButton") ? getDataFromCache() : getDataFromAPI();
        })
        .catch(async (error) => {
          if (
            error?.response?.data?.Errors?.[0]?.toLowerCase() ==
            "access denied."
          ) {
            setAccessDenied(true);
          } else {
            setErrorApiCall(true);
          }
          setIsLoading(false);
        });
    }
  }, [menuState.submenu.name]);

  useEffect(() => {
    if (toggleHamburger == false && window.innerWidth > 768) {
      handleHamburgerChange();
    }
  }, []);

  const getDataFromAPI = () => {
    setTimeout(() => {
      if (isVisible(props.id)) {
        setIsApiCalled((prevValue) => {
          if (prevValue === false) {
            getCardLimit();
            getData();
            return true;
          }
          return false;
        });
      }
    }, 1000);
  };

  const getDataFromCache = () => {
    window.history.replaceState(null, "", props?.id + "?loadFromCache=true");
    // getting data from cache
    let dataItem = JSON.parse(localStorage.getItem(props.name));
    if (!dataItem) {
      //if data is not present in cache, we are calling api
      getDataFromAPI();
      return;
    }

    // else setting data from cache
    setAllCardsData(dataItem.allCardsData);
    setCardFetchDetails(dataItem.cardFetchDetails);
    setContentTypeLevelFlags(dataItem.contentTypeLevelConfig);
    // set the flags again
    props.setNewButtonConfig(
      dataItem?.contentTypeLevelConfig?.[1]?.featureFlagsDetails?.addNew
    );
    props.setShortcutButtonConfig(
      dataItem?.contentCategories?.[1]?.featureFlagsDetails?.myShortcuts
    );
    props.setLibraryButtonConfig(
      userInfo?.teams?.isGlobalContentCompany == true
        ? dataItem?.contentTypeLevelConfig?.[0]?.featureFlagsDetails?.library
        : dataItem?.contentTypeLevelConfig?.[1]?.featureFlagsDetails?.library
    );
    setDetailsPageAccess(dataItem?.contentTypeLevelConfig);
    setIsLoading(false);
  };
  const updateCardInfo = (updatedCardData, id, sectionNames, isVoted) => {
    sectionNames?.map((sectionName) => {
      updateEachSection(updatedCardData, id, sectionName, isVoted);
    });
  };
  const updateEachSection = (updatedCardData, id, sectionName, isVoted) => {
    let allCardsDataObj = { ...allCardsData };
    let sectionCardData = allCardsDataObj?.["sections"]?.[sectionName];
    if (Object.entries(allCardsData).length !== 0) {
      if (sectionCardData?.length > 0) {
        let filteredCard = sectionCardData?.filter((item) => {
          return item?.metaDataId === id;
        })?.[0];
        if (filteredCard) {
          let index = sectionCardData?.findIndex(
            (item) => item?.metaDataId === id
          );
          let contentInfoObj = filteredCard?.contentInfo?.[0];
          let contentCustomDataObj = filteredCard?.contentCustomData;
          contentInfoObj = {
            ...contentInfoObj,
            content: updatedCardData?.contentInfo?.[0]?.content,
          };
          contentCustomDataObj = {
            ...contentCustomDataObj,
            tileCustomData: updatedCardData?.contentCustomData?.tileCustomData,
          };
          filteredCard = {
            ...filteredCard,
            contentCustomData: contentCustomDataObj,
            status: updatedCardData?.status,
          };
          filteredCard["contentInfo"][0] = contentInfoObj;
          filteredCard["customData"]["userLevel"]["voted"] =
            isVoted == true || isVoted == false ? isVoted : true;
          sectionCardData[index] = filteredCard;
          allCardsDataObj["sections"][sectionName] = sectionCardData;
          setAllCardsData(allCardsDataObj);
        }
      }
    }
  };
  const handleReload = async () => {
    try {
      let topbarHeight = document
        .getElementById("topbar")
        .getBoundingClientRect().height;
      scrollIntoViewWithOffset(props?.id, -(topbarHeight + 25 - 10)); //added -10 so the scroll is little bigger so menuState and breadcrumb work correctly.
    } catch (e) {}
    await getData(undefined, true);
  };
  const handleLoadMore = (type) => {
    setCardFetchDetails((prevData) => {
      return {
        ...prevData,
        [type]: {
          ...prevData[type],
          isFetching: true,
        },
      };
    });
    getData(type);
  };

  const handleOnCardClick = (params, section) => {
    let detailsAccess;
    if (params?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL) {
      detailsAccess =
        detailsPageAccess?.[0]?.featureFlagsDetails?.detailsPage
          ?.detailsPageDisplay == undefined
          ? true
          : detailsPageAccess[0]?.featureFlagsDetails?.detailsPage
              ?.detailsPageDisplay;
    } else {
      detailsAccess =
        detailsPageAccess?.[1]?.featureFlagsDetails?.detailsPage
          ?.detailsPageDisplay == undefined
          ? true
          : detailsPageAccess[1]?.featureFlagsDetails?.detailsPage
              ?.detailsPageDisplay;
    }
    if (detailsAccess == true) {
      // navigate(`/taxalerts/details/${params.metaDataId}`);
      //console.log("Conferences Card Clicked");
      let allCardsWithConfiguration = {
        allCardsData: allCardsData,
        cardFetchDetails: cardFetchDetails,
        contentTypeLevelConfig: contentTypeLevelFlags,
      };
      localStorage.setItem(
        props.name,
        JSON.stringify(allCardsWithConfiguration)
      );

      if (params?.contentInfo) {
        params?.contentInfo?.map((item) => {
          if (item.contentSourceType === "URL") {
            // go to link outside of the teams
            console.log(item.url);
            try {
              let urlToBeOpened = atob(item.url);
              viewCounterFunc(params);

              userInfo.loggedInWeb === true
                ? window.open(urlToBeOpened, "_blank")
                : app.getContext().then((item) => {
                    if (item.app.host.name == "Teams") {
                      app.openLink(urlToBeOpened);
                    } else {
                      window.open(urlToBeOpened, "_blank");
                    }
                  });
            } catch (e) {
              console.log(e);
            }
          } else if (item.contentSourceType === "Template") {
            // download the pdf
            //downloadPdf(params.metaDataId, props.contentTypeId, item.content);

            // navigate(
            //   `/pdfDetails/${params.metaDataId}/${props.contentTypeId}/${
            //     params?.contentSecurity?.availability?.domainLevel ==
            //     Constant.GLOBAL
            //       ? true
            //       : false
            //   }?prevPath=${window.location.pathname}&section=${section}&BB=${
            //     location?.state?.isFromBB ? true : false
            //   }`
            // );
            viewCounterFunc(params, true);
            handleOpenPdf(params, props.contentTypeId);
          } else if (
            item?.contentSourceType?.toLowerCase() === "HTML"?.toLowerCase()
          ) {
            //navigate to detail page
            navigate(
              `/itemDetails/${params.metaDataId}/${props.contentTypeId}/${
                params?.contentSecurity?.availability?.domainLevel ==
                Constant.GLOBAL
                  ? true
                  : false
              }?prevPath=${window.location.pathname}&section=${section}&BB=${
                location?.state?.isFromBB ? true : false
              }`
            );
          }
        });
      } else {
        navigate(
          `/itemDetails/${params.metaDataId}/${props.contentTypeId}/${
            params?.contentSecurity?.availability?.domainLevel ==
            Constant.GLOBAL
              ? true
              : false
          }?prevPath=${window.location.pathname}&section=${section}&BB=${
            location?.state?.isFromBB ? true : false
          }`
        );
      }
    } else {
      handleNotificationMsgsData({
        showMsgBar: true,
        started: false,
        completed: true,
        msg: Constant.ACCESSDENIED_DETAILSPAGE,
        type: "fail",
        isFailed: false,
      });
    }
  };

  const handleOpenPdf = async (params, contentTypeId) => {
    try {
      setPdfRender(true);
      const response = await openPdfInNewTab(
        params.metaDataId,
        contentTypeId,
        params?.contentSecurity?.availability?.domainLevel === Constant.GLOBAL,
        userInfo?.teams?.companyId
      );

      // Handle the response object here
      if (response.statusCode === "STCS001" && response.pdfUrl !== "") {
        setPdfRender(false);
        if (userInfo.loggedInWeb) {
          window.open(response.pdfUrl, "_blank");
        } else {
          app.openLink(response.pdfUrl);
        }
      } else if (response.statusCode === "STCS002") {
        setPdfRender(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: Constant.ContentNotAvailable,
          type: "fail",
          isFailed: false,
        });
      }
    } catch (error) {
      setPdfRender(false);
      console.error("Error opening PDF:", error);
    }
  };

  const viewCounterFunc = (params, updateLS = false) => {
    let paramViewCounter = {
      metaDataId: params.metaDataId,
    };
    postData(
      paramViewCounter,
      APIEndPoints.CONTENT_VIEW_COUNTER(
        props.contentTypeId,
        params?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((res) => {
        if (res.data.statusCode == "STCS002" || res.data == "") {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.ContentNotAvailable,
            type: "fail",
            isFailed: false,
          });
        } else {
          TrackEvent(TelemetryEventNames.UCC_CONTENT_VIEW_EVENT, userInfo, {
            SectionName:
              props?.contentTypeId == 4
                ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                : TelemetryEventNames.SECTION_TYPE_CONTENT,
            ContentTypeId: props.contentTypeId.toString() ?? "",
            ContentTypeName:
              getContentTypeNamesById2(props.contentTypeId) ?? "",
            MetadataId: params.metaDataId.toString(),
            UniqueId: params.contentUniqueId ?? "",
            Title: params.title ?? "",
            PublishDate: params.publishedDate.toString() ?? "",
            LatestViewCount: res?.data?.resultData?.viewCount.toString() ?? "",
          });
          setChangeViewCount(res?.data?.resultData?.viewCount);
          setAllCardsData(
            updateViewCounter(
              params,
              res?.data?.resultData?.viewCount,
              allCardsData,
              updateLS,
              props?.name
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  // const handleOnCardClick = (params) => {
  //   navigate(`/taxalerts/details/${params.metaDataId}`);
  // };

  const updatePinInfoByMetaDataId = (metaDataId, pinInfoObject) => {
    setAllCardsData((prevData) => {
      const newData = _.cloneDeep(prevData);

      for (const sectionName in newData?.sections) {
        const section = newData?.sections[sectionName];
        const match = section.find((item) => item?.metaDataId === metaDataId);

        if (match) {
          match.customData.pinInfo = {
            ...match.customData.pinInfo,
            ...pinInfoObject,
          };
        }
      }

      return newData;
    });
  };

  if (errorApiCall) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;

  return (
    <>
      {pdfRender && (
        <div>
          <ReusableLoader isComponentBody={true} isOverlay={true} />
        </div>
      )}
      {isLoading === true ? (
        <ReusableLoader />
      ) : (
        Object.entries(allCardsData).length !== 0 && (
          <div className="CardComponent">
            <p className="conferencesCardsName">
              {cardFetchDetails.new.subContentTypeDisplayName}
            </p>
            {allCardsData?.["sections"]?.new?.length > 0 ? (
              <>
                <div className="CardList">
                  {allCardsData?.["sections"]?.new?.map((item) => (
                    <CardExampleSize
                      key={item.metaDataId}
                      id={item.metaDataId}
                      item={item}
                      config={
                        item?.contentSecurity?.availability?.domainLevel ==
                        Constant.GLOBAL
                          ? cardFetchDetails.new.config?.[0]
                              ?.featureFlagsDetails
                          : cardFetchDetails.new.config?.[1]
                              ?.featureFlagsDetails
                      }
                      isImage={true}
                      contentType={props.contentTypeId}
                      pathname={props.id}
                      onClick={handleOnCardClick}
                      fixHeight={true}
                      showMoreButton={true}
                      handleReload={handleReload}
                      contentTypeName={props?.name}
                      section={cardFetchDetails.new.subContentType}
                      contentSource={item.contentSource}
                      updateCardData={updateCardInfo}
                      sectionName={["new", "topprevious"]}
                      isPolls={true}
                      updatePinInfoByMetaDataId={updatePinInfoByMetaDataId}
                    />
                  ))}
                </div>

                <LoadMore
                  isFetching={cardFetchDetails.new.isFetching}
                  hasMoreData={cardFetchDetails.new.hasMoreData}
                  onClick={() => handleLoadMore("new")}
                />
              </>
            ) : (
              <ComingSoon />
            )}

            <p className="conferencesCardsName">
              {cardFetchDetails.topprevious.subContentTypeDisplayName}
            </p>
            {allCardsData?.["sections"]?.topprevious?.length > 0 ? (
              <>
                <div className="CardList">
                  {allCardsData?.["sections"]?.topprevious?.map((item) => (
                    <CardExampleSize
                      key={item.metaDataId}
                      id={item.metaDataId}
                      item={item}
                      config={
                        item?.contentSecurity?.availability?.domainLevel ==
                        Constant.GLOBAL
                          ? cardFetchDetails.topprevious.config?.[0]
                              ?.featureFlagsDetails
                          : cardFetchDetails.topprevious.config?.[1]
                              ?.featureFlagsDetails
                      }
                      isImage={true}
                      contentType={props.contentTypeId}
                      pathname={props.id}
                      onClick={handleOnCardClick}
                      fixHeight={true}
                      showMoreButton={true}
                      handleReload={handleReload}
                      contentTypeName={props?.name}
                      section={cardFetchDetails.topprevious.subContentType}
                      contentSource={item.contentSource}
                      updateCardData={updateCardInfo}
                      sectionName={["new", "topprevious"]}
                      isPolls={true}
                      updatePinInfoByMetaDataId={updatePinInfoByMetaDataId}
                    />
                  ))}
                </div>

                <LoadMore
                  isFetching={cardFetchDetails.topprevious.isFetching}
                  hasMoreData={cardFetchDetails.topprevious.hasMoreData}
                  onClick={() => handleLoadMore("topprevious")}
                />
              </>
            ) : (
              <ComingSoon />
            )}
          </div>
        )
      )}
      {location?.state?.contentTypeName?.toLowerCase() ===
        props?.name?.toLowerCase() &&
        location?.state?.companyId > 0 && (
          <DeepLinkPopup
            location={{ ...location.state }}
            allCardsData={allCardsData}
            updateCardInfo={updateCardInfo}
            handleReload={handleReload}
            handleOnCardClick={handleOnCardClick}
            name={getContentTypeNamesById2(location?.state?.contentType) ?? ""}
            path={getContentTypePathsById(location?.state?.contentType)}
            contentType={
              location?.state?.contentType &&
              parseInt(location?.state?.contentType)
            }
            updatePinInfoByMetaDataId={updatePinInfoByMetaDataId}
            handleAPIError={handleAPIError}
            {...props}
          />
        )}
    </>
  );
};
