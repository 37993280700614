import React from "react";
import "./reusableLoader.css";
import { Loader } from "@fluentui/react-northstar";
import { Flex } from "@fluentui/react-northstar";

function ReusableLoader(props) {
  return props?.isOverlay === true ? (
    <Flex
      className={
        props?.isChat === true
          ? "chat_component_loader"
          : props?.isCalendar === true
          ? "calender_component_loader"
          : props?.isMail === true
          ? "mail_component_loader"
          : props?.isAppBody
          ? "appbody_component_loader"
          : props.isComponentBody
          ? "is_component_body"
          : props?.isBookmark
          ? "bookmarkLayover"
          : props?.className
      }
      style={{
        top: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.5)",
        zIndex: 2,
        position:
          props?.isMail === true ||
          props?.isChat === true ||
          props?.isCalendar === true
            ? "absolute"
            : "fixed",
      }}
    >
      <Loader
        label={props?.label}
        labelPosition={props?.labelPosition}
        secondary={props?.secondary}
      />
    </Flex>
  ) : (
    <div
      className={`${
        props?.privacyNotice
          ? "reusableloader_container_pn"
          : "reusableloader_container"
      }  ${props?.pollsLoader ? props?.pollsLoader : ""} `}
    >
      <Loader
        label={props?.label}
        labelPosition={props?.labelPosition}
        secondary={props?.secondary}
      />
    </div>
  );
}

export default ReusableLoader;
