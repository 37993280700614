import React, { useEffect, useRef, useState } from "react";
import { MdOutlineClear } from "react-icons/md";
import {
  ImageUtil,
  getContentTypeNamesById2,
  checkIfDeviceIsMob,
} from "../../../utility/utility";
import { Dropdown, Flex, Loader } from "@fluentui/react-northstar";
import CustomizedToolTip from "../../../commonui/tooltip/toolTip";
import { UseUserManagementContext } from "../../../context/usermanagementcontext/userManagementContext";
import _ from "lodash";
import { GrClose } from "react-icons/gr";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import { Checkbox } from "@fluentui/react-northstar";
import DialogBox from "../../../commonui/dialogBox/DialogBox";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import TelemetryEventNames from "../../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../../services/appInsights";
import { BiError } from "react-icons/bi";
import { BsLinkedin } from "react-icons/bs";
import {
  deleteDataByPayload,
  getData,
  postData,
} from "../../../services/customApis";
import APIEndPoints from "../../../utility/apiendpoints";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import { app, pages } from "@microsoft/teams-js";
import Constant from "../../../utility/constants";
import "./UsersTable.css";

const RoleDropdown = (props) => {
  const { userProfileId, user } = props;

  const { usersData, handleDataChange } = UseUserManagementContext();
  const { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  const { usersList, fetchingRoles, roles, addUsersList, filterUsersList } =
    usersData;

  const [loader, setLoader] = useState(false);
  const [filteredRoles, setFilteredRoles] = useState(
    user?.isExternal
      ? roles?.filter((item) => item?.roleType === 2 || item?.roleType === 3)
      : roles?.filter((item) => item?.roleType === 1 || item?.roleType === 3)
  );
  const [selectedRole, setSelectedRole] = useState(user?.role);

  const changeRole = (event, value) => {
    setLoader(true);

    let role = roles?.find(
      (i) => i?.roleName?.toLowerCase() === value?.toLowerCase()
    );
    let activeRole = roles?.find(
      (i) => i?.roleName?.toLowerCase() === user?.role?.toLowerCase()
    );
    let tempUser;
    console.log(user);
    let reqObj = {
      userProfileId: user?.userProfileId ?? 0,
      userEmail: user.email,
      roleId: role?.roleId ?? 0,
      accessControlId: user?.accessControlId ?? 0,
      activeRoleId: activeRole?.roleId ?? 0,
    };

    // let reqObj = {
    //     userProfileId: 102,
    //     userEmail: "Nithin.B1@gds.ey.com",
    //     roleId: 1,
    //     roleValue: "Owner",
    //     accessControlId: 25081,
    //     activeRoleId: 1
    // };

    postData(
      reqObj,
      APIEndPoints.USER_MANAGEMENT_CHANGE_ROLE(userInfo.teams.companyId)
    )
      .then((res) => {
        const updatedUsersList = _.map(usersList, (user) => {
          if (user?.userProfileId === userProfileId) {
            tempUser = user;
            return { ...user, role: role?.roleName };
          }
          return user;
        });
        setSelectedRole(role?.roleName);
        const updatedAddUsersList = _.map(addUsersList, (user) => {
          if (user?.userProfileId === userProfileId) {
            tempUser = user;
            return { ...user, role: role?.roleName };
          }
          return user;
        });
        const updatedFilterUsersList = _.map(filterUsersList, (user) => {
          if (user?.userProfileId === userProfileId) {
            tempUser = user;
            return { ...user, role: role?.roleName };
          }
          return user;
        });

        handleDataChange({
          usersList: updatedUsersList,
          addUsersList: updatedAddUsersList,
          filterUsersList: updatedFilterUsersList,
        });

        handleDataChange({
          owner:
            updatedUsersList?.filter(
              (item) =>
                item?.email?.toLowerCase() === userInfo?.email?.toLowerCase() &&
                _.includes(item?.role?.toLowerCase(), "owner")
            )?.length > 0,
        });

        TrackEvent(TelemetryEventNames.UCC_UMM_UPDATE_ROLE, userInfo, {
          SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
          OldRole: tempUser?.role,
          CurrentRole: role?.roleName ?? "Member",
          ImpactedUserEmail: user?.email,
          ImpactedUserName: user?.displayName ?? "",
          Title: user?.jobTitle ?? "",
          Organization: user?.companyName ?? "",
          Location: user?.officeLocation ?? "",
          IsInternal: !user?.isExternal ?? false,
        });
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: Constant.CHANGE_ROLE_SUCCESS,
          type: "Success",
          isFailed: false,
        });
      })
      .catch((err) => {
        handleDataChange({
          usersList: usersList.map((i) =>
            i?.userProfileId === tempUser?.userProfileId
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
          addUsersList: addUsersList.map((i) =>
            i?.userProfileId === tempUser?.userProfileId
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
          filterUsersList: filterUsersList.map((i) =>
            i?.userProfileId === tempUser?.userProfileId
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
        });
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors[0],
          type: "Fail",
          isFailed: true,
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setFilteredRoles(
      user?.isExternal
        ? roles?.filter((item) => item?.roleType === 2 || item?.roleType === 3)
        : roles?.filter((item) => item?.roleType === 1 || item?.roleType === 3)
    );
  }, [usersList, addUsersList, fetchingRoles, roles]);

  const [open, setOpen] = useState(false);

  const refs = useRef(null);
  return (
    <>
      {" "}
      {loader ? (
        <span className="roleDDWidth">
          <Loader className="roles-loader" />
        </span>
      ) : (
        <>
          {" "}
          {props?.tableType === 2 ? (
            <Dropdown
              items={filteredRoles?.map((item) => item?.roleName)}
              // items={filteredRoles}
              getA11ySelectionMessage={{
                onAdd: (item) => `${item} has been selected.`,
              }}
              defaultValue={selectedRole}
              className="roleDDWidth"
              fluid
              onChange={(event, item) => {
                changeRole(event, item?.value);
              }}
              loading={fetchingRoles}
              open={open}
              onFocus={() => setOpen((prev) => !prev)}
              onBlur={() => setOpen(false)}
              ref={refs}
            />
          ) : (
            <Dropdown
              items={filteredRoles?.map((item) => item?.roleName)}
              // items={filteredRoles}
              getA11ySelectionMessage={{
                onAdd: (item) => `${item} has been selected.`,
              }}
              defaultValue={selectedRole}
              className="roleDDWidth"
              fluid
              onChange={(event, item) => {
                changeRole(event, item?.value);
              }}
              loading={fetchingRoles}
            />
          )}
        </>
      )}
    </>
  );
};

const UsersTable = (props) => {
  const { showHeaders, list, user } = props;
  // const [localList, setLocalList] = useState(list);
  const { usersData, handleDataChange } = UseUserManagementContext();
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  const {
    usersList,
    fetchingRoles,
    addUsersList,
    filterUsersList,
    owner,
    featureFlags,
    roles,
  } = usersData;

  const [isLoading, setIsLoading] = useState({});
  const [isFail, setIsFail] = useState(false);
  const [openDeleteUserDialog, setDeleteUserOpenDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [item, setItem] = useState({});

  const setItemOpenDialoge = (item) => {
    setItem(item);
    setDeleteUserOpenDialog(true);
  };

  const changeUserList = (item) => {
    const updatedUsersList = list.filter(
      (user) => user.content !== item.content
    );

    props?.handleUserListChange(updatedUsersList, false);
  };

  const changeContentAuthorRole = async (
    event,
    togglestate,
    changedItem,
    cboxid
  ) => {
    setIsLoading((currentState) => ({ ...currentState, [cboxid]: true }));

    setIsFail((currentState) => ({ ...currentState, [cboxid]: false }));
    const updateListItem = (listItems) =>
      listItems.map((userItem) => {
        if (userItem.userProfileId === cboxid) {
          let rolesArray = userItem.roles.split(",");
          if (togglestate.checked) {
            if (!rolesArray.includes(Constant.CONTENT_AUTHOR_ROLE_NAME)) {
              rolesArray.push(Constant.CONTENT_AUTHOR_ROLE_NAME);
            }
          } else {
            const index = rolesArray.indexOf(Constant.CONTENT_AUTHOR_ROLE_NAME);
            if (index > -1) {
              rolesArray.splice(index, 1);
            }
          }
          let newRole = rolesArray.join(",");
          return { ...userItem, roles: newRole };
        }
        return userItem;
      });

    // setLocalList(updatedList);

    handleDataChange({
      usersList: updateListItem(usersList),
      addUsersList: updateListItem(addUsersList),
      filterUsersList: updateListItem(filterUsersList),
    });

    const res = await getData(
      APIEndPoints.USER_MANAGEMENT_GET_ROLES(userInfo.teams.companyId)
    );
    const contentAuthorRoleId = res.data.output_Data
      .filter((role) => role.roleName === Constant.CONTENT_AUTHOR_ROLE_NAME)
      .map((role) => role.roleId)[0];

    if (!contentAuthorRoleId) {
      console.error(Constant.NO_ROLE_ID_ERROR_MSG);
      return;
    }
    let reqObj = {
      userProfileId: changedItem?.userProfileId ?? 0,
      userEmail: changedItem?.email,
      roleId: contentAuthorRoleId,
      accessControlId: user?.accessControlId ?? 0,
      activeRoleId: 0, //need to understand if applies here.
      contentAuthorRoleAction: {
        roleActionType: Number(!togglestate?.checked === true) + 1,
      },
    };
    postData(
      reqObj,
      APIEndPoints.USER_MANAGEMENT_CHANGE_ROLE(userInfo.teams.companyId)
    )
      .then((res) => {
        if (!res) {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.CONTENT_AUTHOR_UPDATED_FAIL,
            type: "Fail",
            isFailed: true,
          });
          throw new Error(Constant.NO_REPONSE_ERROR_MSG);
        }
        if (res.status === 200) {
          setIsFail((currentState) => ({ ...currentState, [cboxid]: false }));
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.CONTENT_AUTHOR_UPDATED_SUCCESS,
            type: "Success",
            isFailed: false,
          });
        } else {
          setIsFail((currentState) => ({ ...currentState, [cboxid]: true }));
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.CONTENT_AUTHOR_UPDATED_FAIL,
            type: "Fail",
            isFailed: true,
          });
        }
      })
      .catch((err) => {
        setIsFail((currentState) => ({ ...currentState, [cboxid]: true }));
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors[0],
          type: "Fail",
          isFailed: true,
        });
      })
      .finally(() => {
        setIsLoading((currentState) => ({ ...currentState, [cboxid]: false }));
      });
  };

  const deleteUser = () => {
    setLoader(true);

    let reqObj = {
      members: [
        {
          userEmail: item?.email,
          isExternal: !item?.isInternal ?? false,
        },
      ],
    };
    deleteDataByPayload(
      APIEndPoints.USER_MANAGEMENT_DELETE_USERS(userInfo.teams.companyId),
      reqObj
    )
      .then((res) => {
        handleDataChange({
          usersList: usersList.filter(
            (i) => i?.userProfileId !== item?.userProfileId
          ),
          addUsersList: addUsersList.filter(
            (i) => i?.userProfileId !== item?.userProfileId
          ),
          filterUsersList: filterUsersList.filter(
            (i) => i?.userProfileId !== item?.userProfileId
          ),
        });

        let tempUser = usersList.filter(
          (i) => i?.userProfileId === item?.userProfileId
        )?.[0];

        TrackEvent(TelemetryEventNames.UCC_UMM_DELETE_USER, userInfo, {
          SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
          ImpactedUserEmail: tempUser?.email,
          CurrentRole: tempUser?.role ?? "Member",
          ImpactedUserName: tempUser?.displayName ?? "",
          Title: tempUser?.title ?? "",
          Organization: tempUser?.organization ?? "",
          Location: tempUser?.location ?? "",
          IsInternal: tempUser?.isInternal ?? false,
        });

        if (item?.email?.toLowerCase() === userInfo?.email?.toLowerCase()) {
          let tempComp = userInfo?.allCompanies?.filter(
            (i) => i?.companyId !== userInfo.teams.companyId
          );
          window.localStorage.removeItem("curUserSelectedCompany");
          if (tempComp.length > 0) {
            handleUserInfoChange({
              teams: tempComp[0],
            });
            handleUserInfoChange({
              allCompanies: tempComp,
            });

            handleDataChange({
              resetCompanies: !usersData?.resetCompanies,
            });
          } else if (res?.data?.[0]?.isExternalUser) {
            // handleUserInfoChange({ privacyAgreementDeclined: true });
            handleUserInfoChange({ teams: { privacyAgreementDeclined: true } });
            handleDataChange({
              resetCompanies: !usersData?.resetCompanies,
            });
          } else {
            // window.location.href = "/";
            app.getContext().then((item) => {
              if (item.page.id != Constant.HomeTab) {
                let parms = {
                  appId: Constant.GetUserAppId(item?.user?.userPrincipalName),
                  pageId: Constant.HomeTab,
                };
                pages.navigateToApp(parms);
              } else {
                window.location.href = "/";
              }
            });
          }
        }

        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: Constant.USER_DELETE_SUCCESS,
          type: "Success",
          isFailed: false,
        });
      })
      .catch((err) => {
        handleDataChange({
          usersList: usersList.map((i) =>
            i?.userProfileId === item?.userProfileId
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
          addUsersList: addUsersList.map((i) =>
            i?.userProfileId === item?.userProfileId
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
          filterUsersList: filterUsersList.map((i) =>
            i?.userProfileId === item?.userProfileId
              ? {
                  ...i,
                  leaveError: true,
                  leaveErrorMsg: err?.response?.data?.Errors,
                }
              : i
          ),
        });
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors
            ? err?.response?.data?.Errors
            : Constant.USER_DELETE_FAIL,
          type: "fail",
          isFailed: false,
        });
      })
      .finally(() => {
        setDeleteUserOpenDialog(false);
        setItem({});
        setLoader(false);
      });
  };

  const onLinkedInProfileClick = (item) => {
    window.open(item.linkedinProfileURL, "_blank");
  };

  return (
    <>
      <table className="responsive-table">
        {showHeaders && list?.length > 0 && (
          <thead>
            <tr>
              <th className="minimal-width">Name</th>
              {props?.tableType !== 2 && (
                <>
                  {/*  LinkedInIcon */}
                  <th className="minimal-width">&nbsp;</th>
                  <th className="minimal-width">Organization</th>
                  <th className="minimal-width">Title</th>
                  <th className="minimal-width">Location</th>
                </>
              )}
              <th className="responsive-text"></th>
              <th className="minimal-width">
                {Constant.CONTENT_AUTHOR_ROLE_NAME}
              </th>
              {fetchingRoles ? null : featureFlags?.deleteMember
                  ?.deleteMemberDisplay && owner ? (
                <th className="minimal-width">&nbsp;</th>
              ) : null}
            </tr>
          </thead>
        )}
        <tbody className="fontSize">
          {list.map((item, index) => (
            <tr
              key={Math.random().toString(36).substr(2, 5)}
              className={
                props?.tableType === 2 ? "type-2-table" : "type-1-table"
              }
            >
              {props?.fromCustomUserGroup && (
                <td>
                  <span className="username_and_avatar_usergroup">
                    <img
                      className="users-avatar"
                      src={ImageUtil(item?.name)}
                      alt=""
                    />
                    &nbsp;&nbsp;
                    <CustomizedToolTip content={item?.name} width={true}>
                      <span className="users-avatar__username_customusergroup">
                        {item?.name}
                      </span>
                    </CustomizedToolTip>
                  </span>
                </td>
              )}
              <td style={{ textAlign: "left" }}>
                {/* <span
                    className="username_and_avatar"
                    style={{ float: "left" }}
                    
                  {/* </span> */}
                <CustomizedToolTip content={item?.name} width={true}>
                  <div className="standard-width">
                    {/*  showEllipses users-avatar__username  */}
                    <img
                      className="users-avatar"
                      src={ImageUtil(item?.name)}
                      alt=""
                    />
                    &nbsp;
                    {item?.name}
                  </div>
                </CustomizedToolTip>
              </td>
              {!props?.fromCustomUserGroup && (
                <td className="td-linkedInProfile">
                  {featureFlags?.viewLinkedinProfileURL
                    ?.viewLinkedinProfileURLDisplay &&
                    item?.linkedinProfileURL !== "" &&
                    item?.linkedinProfileURL !== null && (
                      <span>
                        <CustomizedToolTip
                          content={"LinkedIn Account"}
                          width={true}
                        >
                          <span className="showEllipses linkedIn-profile-icon">
                            <BsLinkedin
                              onClick={() => onLinkedInProfileClick(item)}
                            />
                          </span>
                        </CustomizedToolTip>
                      </span>
                    )}
                </td>
              )}
              <td style={{ textAlign: "left" }}>
                <span className="users-list__tooltip">
                  <CustomizedToolTip content={item?.organization} width={true}>
                    <div className="more-width">{item?.organization}</div>
                  </CustomizedToolTip>
                </span>
              </td>
              <td style={{ textAlign: "left" }}>
                <span className="users-list__tooltip">
                  <CustomizedToolTip content={item?.title} width={true}>
                    <div className="less-width">{item?.title}</div>
                  </CustomizedToolTip>
                </span>
              </td>
              {!props?.fromAddEYUsers && (
                <td style={{ textAlign: "left" }}>
                  <span className="users-list__tooltip">
                    <CustomizedToolTip content={item?.location} width={true}>
                      <div className="more-width">{item?.location}</div>
                    </CustomizedToolTip>
                  </span>
                </td>
              )}
              <td style={{ display: "block", textAlign: "left" }}>
                {item?.leaveError && (
                  <CustomizedToolTip
                    content={item?.leaveErrorMsg}
                    normalTooltip={true}
                  >
                    <span className="leaveCompany__error">
                      <BiError />
                    </span>
                  </CustomizedToolTip>
                )}
                {props?.fromCustomUserGroup ? null : fetchingRoles ? (
                  <Loader className="roles-loader" />
                ) : featureFlags?.editRole?.editRoleDisplay &&
                  featureFlags?.editRole?.editRoleEnabled &&
                  owner ? (
                  <RoleDropdown
                    userProfileId={item?.userProfileId}
                    user={item}
                    tableType={props?.tableType}
                  />
                ) : (
                  item?.role
                )}
              </td>
              <td style={{ textAlign: "left" }}>
                {props?.fromCustomUserGroup ? null : (
                  <Checkbox
                    key={item?.userProfileId}
                    toggle
                    style={item?.isExternal ? { display: "none" } : {}}
                    defaultChecked={
                      (item?.role?.includes(
                        Constant.CONTENT_AUTHOR_ROLE_NAME
                      ) ||
                      item?.role?.includes(Constant.OWNER_ROLE_NAME) ||
                      item?.roles?.includes(
                        Constant.CONTENT_AUTHOR_ROLE_NAME
                      ) ||
                      item?.roles?.includes(Constant.OWNER_ROLE_NAME)
                        ? true
                        : false) ^ isFail[item?.userProfileId]
                    }
                    // disabled={!isLoading[item?.userProfileId] ? item?.roles?.includes("Owner") ? true : false : true}
                    disabled={
                      !isLoading[item?.userProfileId]
                        ? featureFlags?.editRole?.editRoleDisplay &&
                          featureFlags?.editRole?.editRoleEnabled &&
                          owner
                          ? item?.role?.includes(Constant.OWNER_ROLE_NAME)
                            ? true
                            : false
                          : true
                        : true
                    }
                    tabIndex={0}
                    onChange={(event, togglestate) => {
                      changeContentAuthorRole(
                        event,
                        togglestate,
                        item,
                        item?.userProfileId
                      );
                    }}
                    className={
                      item?.role?.includes(Constant.OWNER_ROLE_NAME) &&
                      "content-author-checkbox"
                    }
                  />
                )}
              </td>
              {props?.fromCustomUserGroup ? (
                <td>
                  <span
                    tabIndex={0}
                    title="Delete"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        changeUserList(item);
                      }
                    }}
                    onClick={() => changeUserList(item)}
                    style={{ cursor: "pointer", paddingRight: "10px" }}
                  >
                    &#10005;
                  </span>
                </td>
              ) : fetchingRoles ? null : featureFlags?.deleteMember
                  ?.deleteMemberDisplay && owner ? (
                <td>
                  <span
                    tabIndex={0}
                    title="Delete"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setItemOpenDialoge(item);
                      }
                    }}
                    onClick={() => setItemOpenDialoge(item)}
                    style={{ cursor: "pointer" }}
                  >
                    &#10005;
                  </span>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
      <DialogBox
        cancelButton={"Cancel"}
        onCancelMethod={() => {
          setDeleteUserOpenDialog(false);
        }}
        closeOnOutsideClick={false}
        confirmButton={"Delete"}
        onConfirmMethod={deleteUser}
        content={
          loader === true ? (
            <div
              className={loader && "loaderLayover userManagementLoaderLayover"}
            >
              <ReusableLoader
                isOverlay={true}
                label={
                  <p>
                    Are you sure you want to delete "{item?.name}" from "
                    {userInfo.teams.companyName}" channel?
                  </p>
                }
                labelPosition={"start"}
              />
            </div>
          ) : (
            <p>
              Are you sure you want to delete "{item?.name}" from "
              {userInfo.teams.companyName}" channel?{" "}
            </p>
          )
        }
        headerName={"Delete User"}
        headerActionName={{
          icon: <GrClose />,
          title: "Close",
          onClick: () => {
            setDeleteUserOpenDialog(false);
          },
        }}
        backdrop={true}
        openDialog={openDeleteUserDialog}
        className={`dialogUserDeleteStyles ${loader && "dialogLayover"}`}
      />
    </>
  );
};

export default UsersTable;
