import React from "react";
import "./staticDialogMessage.css";
import { app } from "@microsoft/teams-js";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { useNavigate } from "react-router-dom";
import { Image } from "@fluentui/react-northstar";

export function DropPopupBox() {
  const { userInfo } = UseUserInfoContext();
  const url =
    "https://sites.ey.com/:w:/r/sites/americastaxquality/_layouts/15/doc2.aspx?sourcedoc=%7B6FFB4118-D1CF-4EE3-9C01-A305AFD8C1EA%7D&file=Professional%20Fees%20Policy%20and%20Supplemental%20Guidance%20for%20US%20Tax.docx&action=default&mobileredirect=true&DefaultItemOpen=1";
  const url2 =
    "https://eyus.sharepoint.com/sites/ASKUSTaxQuality/Lists/FOCUS%20AREA/AllItems.aspx?noAuthRedirect=1";

  return (
    <div class="justifyContent">
      <h3>
        <u>Use of Data</u>
      </h3>
      <p className="margin-bottom-10">
        When{" "}
        <b>
          <u>
            using tax return information provided for a tax compliance
            engagement
          </u>
        </b>
        , you need to confirm EY has client’s consent to use data for purposes
        of pre-engagement scoping. Specifically, before EY can use a client’s
        U.S. tax return information for a purpose other than in connection with
        preparing the client’s tax returns, the client must provide the{" "}
        <b>
          <u>necessary consent</u>
        </b>{" "}
        by:
      </p>
      <ul class="list">
        <li>
          Confirming that the IRC section 7216 paragraph was in the signed Tax
          Compliance Engagement SOW or
        </li>
        <li>
          Signing a separate consent letter that meets the requirements of IRC
          section 7216 and authorizes the use of the client’s specified tax
          return information for such other purposes.
        </li>
      </ul>
      <p>
        When using information provided directly from the client{" "}
        <b>
          <u>not used in a tax compliance engagement</u>
        </b>
        , 7216 consent is{" "}
        <b>
          <u>not</u>
        </b>{" "}
        required.
      </p>
      <br></br>
      <h3>
        <u>Pre-Engagement Scoping Policy and Limitations</u>
      </h3>
      <p>
        <b>
          CATALYST REPORTS AND VISUALIZATIONS ARE NOT INTENDED TO PROVIDE A
          SERVICE THAT CAN BE RELIED ON OR USED FOR ANY PURPOSE. THE CONTENT OF
          THE CATALYST REPORTS AND VISUALIZATIONS DO NOT CONTAIN ANY TAX
          TECHNICAL OR OTHER PROFESSIONAL ADVICE AND SHOULD NOT BE SEEN AS SUCH.
          CATALYST REPORTS AND VISUALIZATIONS MAY ONLY BE USED TO FACILITATE
          INTERNAL ENGAGEMENT TEAM CONVERSATIONS AND/OR CLIENT CONVERSATIONS.
          VISUALIZATIONS SHOULD NOT BE DELIVERED TO THE CLIENT; BUT TEAMS MAY
          SHOW CERTAIN SCREENSHOTS IN THE BELOW ATTACHED PRESENTATION IF IN
          ACCORDANCE WITH THE POLICY.
        </b>
      </p>
      <br></br>
      <p>
        <b>
          PLEASE NOTE THAT IF PRE-ENGAGEMENT SCOPING IS FOR A CLIENT WITH GIS
          REQUIRED APPROVALS INDICATING “GCSP APPROVAL REQUIRED” YOU MUST
          DOCUMENT IN WRITING THE GCSP CONSULTATION AND APPROVAL PRIOR TO
          BEGINNING THIS SCOPING EXERCISE. IF APPLICABLE, THIS SHOULD ALSO
          INCLUDE DOCUMENTING THE GCSP’S CONCLUSIONS AND CONFIRMATION THAT NO
          PROFESSIONAL SERVICES WILL BE PROVIDED AND, AS SUCH, AUDIT COMMITTEE
          PREAPPROVAL OR IESBA PRE-CONCURRENCE IS NOT REQUIRED.
        </b>
      </p>
      <br></br>
      <p>
        The use of the Catalyst Reports and Visualizations in this context is
        subject to the guidance contained in Section 5.5 “Pre-engagement
        scoping” of{" "}
        <a
          className={"hyperlink"}
          onClick={() =>
            userInfo.loggedInWeb === true
              ? window.open(url, "_blank")
              : app.openLink(url)
          }
        >
          {" "}
          Professional Fees Policy and Supplemental Guidance for US Tax.docx
          (ey.com)
        </a>
        . If a client does not want to engage our firm for a feasibility phase,
        then pre-engagement scoping may be appropriate. Pre-engagement scoping
        occurs prior to the engagement and prior to the provision of services.
        It consists of the limited exploration of the client’s facts and
        circumstances to determine whether a particular service may be
        applicable, and potentially beneficial, to the client and assists in the
        budgeting process for the purpose of submitting a proposal to the client
        for performance of the service.
      </p>
      <br></br>
      <p>
        The attached PowerPoint presentation has been drafted to facilitate this
        scoping exercise and includes important guidance as well as required
        disclaimers and disclosures: {}
        <a
          href="/assets/ppt/ITTS Insights.Considerations Assist Dashboard_EY Global Only_2024.9.30.pptx"
          download
        >
          ITTS Insights.Considerations Assist Dashboard_EY Global
          Only_2024.9.30.pptx
        </a>
      </p>
      <br></br>
      <p className="margin-bottom-10">
        <a
          className={"hyperlink"}
          onClick={() =>
            userInfo.loggedInWeb === true
              ? window.open(url, "_blank")
              : app.openLink(url)
          }
        >
          {" "}
          Professional Fees Policy and Supplemental Guidance for US Tax.docx
          (ey.com)
        </a>{" "}
        provides:
      </p>
      <ul class="list">
        <li>
          Service or advice must not be delivered to a potential client during
          pre-engagement scoping.
        </li>
        <li>
          Deliverables, including PowerPoint presentations, should be limited in
          pre-engagement scoping and should not reflect what would normally be
          considered the results of our services or advice. Any communication to
          a taxpayer during pre-engagement scoping should not be sufficiently
          detailed and complete that the client could interpret the
          communication as advice upon which it could rely. It should be noted
          that the more detail provided to a potential client and the fewer
          remaining issues to be resolved, the more likely it is that the
          taxpayer may consider that service or advice is being delivered.
        </li>
        <li>
          Communications to a potential client during pre-engagement scoping,
          whether written or oral, must include a caveat that any information
          provided does not constitute tax advice and cannot be relied upon by
          the taxpayer. The following caveat can be used (included in the
          attached PowerPoint presentation):
          <ul className="no-bullets margin-bottom-10">
            <li className="margin-bottom-10">
              The information contained herein is general in nature and is not
              intended, and should not be construed, as legal, accounting or tax
              advice, or as an opinion provided by EY.
            </li>
            <li className="margin-bottom-10">
              Client also is cautioned that this material may not be applicable
              to, or suitable for, Client’s specific circumstances or needs, and
              may require consideration of nontax and other tax factors if any
              action is to be contemplated. Consequently, Client should not rely
              upon the information provided herein for any purpose.
            </li>
            <li className="margin-bottom-10">
              <span className="color-red">
                {" "}
                [If data is provided by client and not related to EY Tax
                preparation or review services]{" "}
              </span>
              To the extent any information herein contained is provided by the
              Client, it has not been reviewed or verified by EY for accuracy or
              completeness and no tax return review procedures were undertaken
              by EY.
            </li>
          </ul>
        </li>
        <li>
          Pre-engagement scoping is generally not subject to the
          accountant-client privilege under Internal Revenue Code (IRC) section
          7525 and, hence, pre-engagement scoping documents should not include
          any section 7525 privilege label.
        </li>
        <li>
          The expected tax benefit of the service for the taxpayer should only
          be provided in the form of a range or sample calculation. Generally,
          ranges of benefits should avoid unreasonable expectations and should
          not imply too much precision. Any quantification of a range or sample
          calculation of potential benefit should contain the following caveat
          (included in the attached PowerPoint presentation):
          <ul className="no-bullets margin-bottom-10">
            <li className="margin-bottom-10">
              Any quantification of a ranges or sample calculations of potential
              benefits or opportunities provided are preliminary, and while
              based on the experience of EY in the area, are not reasonably
              accurate projections of future benefits.
            </li>
            <li className="margin-bottom-10">
              The actual benefit Client may receive could differ substantially
              from this range (sample). Consequently, this range (sample) cannot
              be relied upon by Client and cannot be used for tax return
              purposes. Additional analysis must be conducted in order to
              determine what tax benefit (if any) Client may receive from this
              service.
            </li>
          </ul>
        </li>
        <li>
          Pre-engagement scoping is conducted for no fee. Time and expenses
          associated with pre-engagement scoping cannot be recouped upon
          engagement by the client (as compared to a feasibility phase in a
          phased engagement where we have been engaged by the client). Because
          of the restriction against billing for pre-engagement scoping,
          managing the amount of time dedicated to pre-engagement scoping is a
          business decision and the alternative of phased engagement feasibility
          (discussed above in section 5.4 of{" "}
          <a
            className={"hyperlink"}
            onClick={() =>
              userInfo.loggedInWeb === true
                ? window.open(url, "_blank")
                : app.openLink(url)
            }
          >
            {" "}
            Professional Fees Policy and Supplemental Guidance for US Tax.docx
            (ey.com)
          </a>
          .) should be considered.
        </li>
        <li>
          The amount of time spent during pre-engagement scoping assessing the
          client’s facts and circumstances as related to a particular service
          offering should be limited. If it is envisioned that time spent on
          pre-engagement scoping for one particular type of service could exceed
          10% of the projected total engagement time to deliver this service,
          then consultation with U.S. Tax Quality is required, and in the case
          of a client subject to U.S. Independence fee guidance, consultation
          with the GCSP is also required prior to beginning the scope assessment
          to determine that the arrangement is otherwise consistent with the
          overarching principles in the U.S. Independence fee guidance.
        </li>
        <li>
          The fee structure for the ensuing engagement cannot directly correlate
          to the range of benefits projected in pre-engagement scoping if the
          engagement is not being performed under a findings-based fee
          structure. For instance, it is impermissible to set fees lower if the
          low end of the range of benefits is realized and higher if the high
          end of the range is realized.
        </li>
      </ul>
      <br></br>
      <p>
        For questions regarding the aforementioned guidance, please contact one
        of the{" "}
        <a
          className={"hyperlink"}
          onClick={() =>
            userInfo.loggedInWeb === true
              ? window.open(url2, "_blank")
              : app.openLink(url2)
          }
        >
          {" "}
          Designated Tax Quality Leaders (TQL)
        </a>
        .
      </p>
      <br></br>
      <p className="font-size-15">
        <b>
          By clicking Yes, I state that I have read and understood the terms and
          conditions of this EY Catalyst Pre-Engagement Scoping User
          Acknowledgement.
        </b>
      </p>
    </div>
  );
}

export function Web_Version_Message() {
  const { userInfo } = UseUserInfoContext();
  const prodURL =
    "https://teams.microsoft.com/l/app/d9e7a294-acc4-490c-bb39-c0ac254e4f76?source=app-details-dialog";
  const uatURL =
    "https://teams.microsoft.com/l/app/269bf58d-f04d-4f6e-95fa-533ff17e925b?source=app-details-dialog";
  const qaURL =
    "https://teams.microsoft.com/l/app/672f6905-c1ad-4321-9a7b-a7b282174436?source=app-details-dialog";

  return (
    <div className={"justifyContent"}>
      <br />
      <p>
        This feature is not supported in the
        <strong> EY Catalyst Connect</strong>{" "}
        {userInfo.loggedInWeb === true ? "web version" : "M365/Outlook"}. To
        access this feature, please follow the link to install the{" "}
        <a
          href={
            window.location.origin.indexOf("https://catalyst.ey.com") !== -1
              ? prodURL
              : window.location.origin.indexOf(
                  "https://catalyst-uat.ey.com"
                ) !== -1
              ? uatURL
              : qaURL
          }
          className={"hyperlink"}
          target="_blank"
        >
          <strong>Catalyst Teams App</strong>
        </a>{" "}
      </p>
      <br />
      <p>
        If you are unable to install the app,{" "}
        <a href="/contactUs" className={"hyperlink"}>
          <strong>Contact Us</strong>
        </a>{" "}
        to initiate the request to get the app installed or reach out to your EY
        Engagement Partner.
      </p>
    </div>
  );
}
